import UnderfloorHeatingCalculatorInput from './underfloor-heating-calculator-input/UnderfloorHeatingCalculatorInput';
import { UnderfloorHeatingCalculatorContextProvider } from './contexts/useUnderfloorHeatingCalculatorContext';
import UnderfloorHeatingCalculatorOutput from './underfloor-heating-calculator-output/UnderfloorHeatingCalculatorOutput';

const UnderfloorHeatingCalculator = () => {
    return (
        <UnderfloorHeatingCalculatorContextProvider>
            <UnderfloorHeatingCalculatorInput />
            <UnderfloorHeatingCalculatorOutput />
        </UnderfloorHeatingCalculatorContextProvider>
    );
};

export default UnderfloorHeatingCalculator;
