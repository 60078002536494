import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../../../shared/types/ReducerType';
import { WallInsulationActions } from './WallInsulationActions';
import { WallInsulationType } from './WallInsulationDefaults';

const WallInsulationReducer: ReducerType<
    WallInsulationType | undefined,
    IReducerAction
> = (state: WallInsulationType | undefined, action: IReducerAction) => {
    switch (action.type) {
        case WallInsulationActions.SET_WALL_INSULATION:
            return {
                monthly: action.payload.monthly,
                annual: action.payload.annual
            };
        case WallInsulationActions.CLEAR_WALL_INSULATION:
            return undefined;
        default:
            return state;
    }
};

export default WallInsulationReducer;
