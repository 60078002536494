import { Month } from '../../../../../../../shared/enums/Month';

export type WallInsulationMonthType = {
    month: Month;
    heatTransferCoefficient: number;
    heatLoss: number;
    energySavings: number;
};

export type WallInsulationAnnualType = {
    heatTransferCoefficient: number;
    heatLoss: number;
    energySavings: number;
};

export type WallInsulationType = {
    monthly: Array<WallInsulationMonthType>;
    annual: WallInsulationAnnualType;
};

const WallInsulation: WallInsulationType | undefined = undefined;

export default WallInsulation;
