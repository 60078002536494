import { Month } from '../../../../../../../shared/enums/Month';

export enum BuildingWindowPaneType {
    SINGLE_PANE = 1,
    DOUBLE_PANE,
    TRIPLE_PANE
}

export type PaneWindowMonthType = {
    month: Month;
    heatLoss: number;
    energySavings: number;
};

export type PaneWindowAnnualType = {
    heatLoss: number;
    energySavings: number;
};

export type PaneWindowType = {
    monthly: Array<PaneWindowMonthType>;
    annual: PaneWindowAnnualType;
};

const PaneWindow: PaneWindowType | undefined = undefined;

export default PaneWindow;
