import AuthService from '../../core/auth/auth.service';

export type SessionDataType = {
    isAuthenticated: boolean;
    token: string | undefined;
    authenticatedUser: AuthenicatedUserType | undefined;
};

export type AuthenicatedUserType = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    // role: ApplicationRole;
};

enum ApplicationRole {
    Admin = 0
}

const user = AuthService.getAuthenticatedUser();
const token = AuthService.getToken();

const SessionData: SessionDataType = {
    isAuthenticated: false,
    token: token,
    authenticatedUser: user
};

export default SessionData;
