import React, { useEffect, useRef, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { IoTriangle } from 'react-icons/io5';
import { LuWind } from 'react-icons/lu';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';
import NumberUtils from '../../../../../../shared/services/numberUtils';
import { useNavigate } from 'react-router-dom';
import {
    Routes,
    RoutingKeys
} from '../../../../../../shared/routes/router.keys';

const MapBuildingDetail = ({ pin, onClose }: any) => {
    const [isFavorite, setFavorite] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const toggleFavorite = () => {
        setFavorite(!isFavorite);
    };

    const navigateToBuildingDetails = (e: any) => {
        e.preventDefault();
        navigate(`${RoutingKeys[Routes.BUILDING_DETAILS]}`.replace(':id', '1'));
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    return (
        <>
            <div
                className="absolute inset-0 bg-black bg-opacity-50 z-20"
                onClick={onClose}
            ></div>
            <div className="absolute inset-0 flex items-center justify-center translate-x-[-20%] z-20">
                <div
                    ref={modalRef}
                    className="bg-white rounded-lg shadow-lg w-80 p-2 relative border-solid border-2 border-primary"
                >
                    <div className="flex flex-row justify-between">
                        <h2
                            className="text-sm font-semibold mb-2 cursor-pointer"
                            onClick={navigateToBuildingDetails}
                        >
                            {pin.name}
                        </h2>
                        <div
                            className="cursor-pointer"
                            onClick={toggleFavorite}
                        >
                            {isFavorite ? (
                                <MdOutlineStar className="text-yellow-400" />
                            ) : (
                                <MdOutlineStarBorder className="text-yellow-400" />
                            )}
                        </div>
                    </div>
                    <img
                        src={pin.imageUrl}
                        alt={pin.name}
                        className="w-full h-40 object-cover mb-4 rounded-xl cursor-pointer"
                        onClick={navigateToBuildingDetails}
                    />
                    <div className="flex flex-row mb-4">
                        <div className="w-1/2 text-xs text-primary">
                            <p className="flex flex-row items-center mb-1">
                                <IoTriangle className="mr-0.5 text-xs p-px" />{' '}
                                +1.79%
                            </p>
                            <p className="flex flex-row items-center">
                                <FaCircle className="mr-0.5 text-xs p-0.5" />{' '}
                                Good deal
                            </p>
                        </div>
                        <div className="w-1/2 text-xs">
                            <p className="flex flex-row items-center text-red-500 mb-1">
                                <IoIosWarning className="mr-1 text-sm border-solid border border-red-500 rounded-full p-px pb-0.5" />{' '}
                                Flood risk 10%
                            </p>
                            <p className="flex flex-row items-center text-primary">
                                <LuWind className="mr-1 text-sm border-solid border border-primary rounded-full p-px pb-0.5" />{' '}
                                Air quality 99%
                            </p>
                        </div>
                    </div>
                    <p className="mb-2 text-xs text-accent">
                        Top vendor in your area: to be implemented
                    </p>
                    <div className="flex flex-row justify-between border-t-solid border-t border-accent items-center pt-2">
                        <p className="font-bold text-sm">
                            ${NumberUtils.formatWithCommas(pin.price.value)}
                        </p>
                        <button
                            className="text-accent text-sm hover:text-gray-700 border-solid border border-accent rounded-xl px-4"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MapBuildingDetail;
