import React from 'react';

type BuildingRequestInfoProps = {
    isOpen: boolean;
    onClose: () => void;
};

const BuildingRequestInfo = ({ isOpen, onClose }: BuildingRequestInfoProps) => {
    if (!isOpen) {
        return <></>;
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-30">
            <div className="bg-white rounded-lg py-8 px-20 mx-auto shadow-lg">
                <p className="text-lg">Thank you for requesting information;</p>
                <p className="text-lg mb-6">
                    we will notify you as soon as possible.
                </p>
                <button
                    className="bg-primary hover:bg-blue-700 text-white py-2 px-12 rounded-lg mx-auto block"
                    onClick={onClose}
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default BuildingRequestInfo;
