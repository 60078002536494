import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import solarPanelFormSchema from './PaneWindowForm.validators';
import ControllableInput from '../../../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../../../shared/ui/buttons/button/Button';
import ControllableCheckbox from '../../../../../../../shared/ui/inputs/controllable-checkbox/ControllableCheckbox';
import usePaneWindowCalculator from '../hooks/usePaneWindowCalculator';
import { BuildingWindowPaneType } from '../state/PaneWindowDefaults';
import { buildingWindowPaneMetadata } from '../../BuildingDetailsOverview.metadata';
import ControllableSelect from '../../../../../../../shared/ui/inputs/controllable-select/ControllableSelect';

export type PaneWindowForm = {
    paneType: { value: number; isDefaultValue: boolean };
    area: { value: number; isDefaultValue: boolean };
    thermalInsulation: { value: number; isDefaultValue: boolean };
    costOfHeatingRate: { value: number; isDefaultValue: boolean };
};

const PaneWindowCalculatorInput = () => {
    const paneWindowCalculator = usePaneWindowCalculator();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<PaneWindowForm>({
        resolver: yupResolver(solarPanelFormSchema),
        defaultValues: {
            paneType: {
                value: BuildingWindowPaneType.SINGLE_PANE,
                isDefaultValue: false
            },
            area: {
                value: 1,
                isDefaultValue: false
            },
            thermalInsulation: {
                value: 1,
                isDefaultValue: false
            },
            costOfHeatingRate: {
                value: 1,
                isDefaultValue: false
            }
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const options = Object.entries(buildingWindowPaneMetadata).map(
        ([value, label]) => ({
            value,
            label
        })
    );

    return (
        <div>
            <div className="flex flex-row justify-between my-6 items-center">
                <div className="w-3/5">
                    <ControllableSelect
                        name={'paneType.value'}
                        control={control}
                        labelText="Pane Type"
                        errorMessage={errors.paneType?.value?.message}
                        required={true}
                        options={options}
                        disabled={watch('paneType.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'paneType.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.paneType?.isDefaultValue?.message}
                />
            </div>
            <div className="flex flex-row justify-between mt-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'area.value'}
                        control={control}
                        type="number"
                        labelText="Area"
                        errorMessage={errors.area?.value?.message}
                        required={true}
                        disabled={watch('area.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'area.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.area?.isDefaultValue?.message}
                />
            </div>
            <div className="flex flex-row justify-between mt-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'thermalInsulation.value'}
                        control={control}
                        type="number"
                        labelText="Thermal Insulation"
                        errorMessage={errors.thermalInsulation?.value?.message}
                        required={true}
                        disabled={watch('thermalInsulation.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'thermalInsulation.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.thermalInsulation?.isDefaultValue?.message
                    }
                />
            </div>
            <div className="flex flex-row justify-between mt-6 mb-12 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'costOfHeatingRate.value'}
                        control={control}
                        type="number"
                        labelText="Cost of Heating Rate"
                        errorMessage={errors.costOfHeatingRate?.value?.message}
                        required={true}
                        disabled={watch('costOfHeatingRate.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'costOfHeatingRate.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.costOfHeatingRate?.isDefaultValue?.message
                    }
                />
            </div>
            <Button
                text="CALCULATE"
                type="button"
                onClick={handleSubmit(paneWindowCalculator.onSubmit)}
            />
        </div>
    );
};

export default PaneWindowCalculatorInput;
