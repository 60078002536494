import { IReducerAction } from '../../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../../shared/types/ReducerType';
import { MapOptionsActions } from './MapOptionsActions';
import MapOptionsDefaults, { MapOptionsType } from './MapOptionsDefaults';

const MapOptionsReducer: ReducerType<MapOptionsType, IReducerAction> = (
    state: MapOptionsType,
    action: IReducerAction
) => {
    switch (action.type) {
        case MapOptionsActions.SET_OPTIONS:
            return { ...state, ...action.payload };
        case MapOptionsActions.CLEAR_OPTIONS:
            return MapOptionsDefaults;
        default:
            return state;
    }
};

export default MapOptionsReducer;
