import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../../../shared/types/ReducerType';
import { PaneWindowActions } from './PaneWindowActions';
import { PaneWindowType } from './PaneWindowDefaults';

const PaneWindowReducer: ReducerType<
    PaneWindowType | undefined,
    IReducerAction
> = (state: PaneWindowType | undefined, action: IReducerAction) => {
    switch (action.type) {
        case PaneWindowActions.SET_PANE_WINDOW:
            return {
                monthly: action.payload.monthly,
                annual: action.payload.annual
            };
        case PaneWindowActions.CLEAR_PANE_WINDOW:
            return undefined;
        default:
            return state;
    }
};

export default PaneWindowReducer;
