import React from 'react';
import { UnderfloorHeatingType } from '../state/UnderfloorHeatingDefaults';
import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';

interface UnderfloorHeatingCalculatorContextType {
    state: UnderfloorHeatingType | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const UnderfloorHeatingContext =
    React.createContext<UnderfloorHeatingCalculatorContextType>(
        {} as UnderfloorHeatingCalculatorContextType
    );

export default UnderfloorHeatingContext;
