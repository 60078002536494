import { Camera, Ellipsoid, Math, Rectangle } from 'cesium';
import { LocationFrameType } from '../state/location-frame/LocationFrameDefaults';

export type CartesianCoordinate = {
    latitude: number;
    longitude: number;
};

const useGeometry = () => {
    const isPointInPolygon = (
        point: CartesianCoordinate,
        vertices: CartesianCoordinate[]
    ): boolean => {
        const { latitude, longitude } = point;

        let inside = false;
        for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
            const xi = vertices[i].latitude,
                yi = vertices[i].longitude;
            const xj = vertices[j].latitude,
                yj = vertices[j].longitude;

            const intersect =
                yi > longitude !== yj > longitude &&
                latitude < ((xj - xi) * (longitude - yi)) / (yj - yi) + xi;

            if (intersect) inside = !inside;
        }

        return inside;
    };

    const isPointInLocationFrame = (
        point: CartesianCoordinate,
        frame: LocationFrameType
    ): boolean => {
        const vertices = [
            frame.topLeft,
            frame.topRight,
            frame.bottomRight,
            frame.bottomLeft
        ];

        return isPointInPolygon(point, vertices);
    };

    const ellipsoidToCartesianCoordinates = (
        camera: Camera,
        ellipsoid: Ellipsoid
    ):
        | [
              northwest: CartesianCoordinate,
              northeast: CartesianCoordinate,
              southwest: CartesianCoordinate,
              southeast: CartesianCoordinate,
              height: number
          ]
        | undefined => {
        const viewRectangle = camera.computeViewRectangle(ellipsoid);

        if (!viewRectangle) {
            return;
        }

        const northwestRectangle = Rectangle.northwest(viewRectangle);
        const northeastRectangle = Rectangle.northeast(viewRectangle);
        const southwestRectangle = Rectangle.southwest(viewRectangle);
        const southeastRectangle = Rectangle.southeast(viewRectangle);

        const height = Rectangle.computeHeight(viewRectangle);

        const northwest = {
            latitude: Math.toDegrees(northwestRectangle.latitude),
            longitude: Math.toDegrees(northwestRectangle.longitude)
        };
        const northeast = {
            latitude: Math.toDegrees(northeastRectangle.latitude),
            longitude: Math.toDegrees(northeastRectangle.longitude)
        };
        const southwest = {
            latitude: Math.toDegrees(southwestRectangle.latitude),
            longitude: Math.toDegrees(southwestRectangle.longitude)
        };
        const southeast = {
            latitude: Math.toDegrees(southeastRectangle.latitude),
            longitude: Math.toDegrees(southeastRectangle.longitude)
        };

        return [northwest, northeast, southwest, southeast, height];
    };

    return {
        isPointInLocationFrame,
        isPointInPolygon,
        ellipsoidToCartesianCoordinates
    };
};

export default useGeometry;
