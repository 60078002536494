import React, { useState } from 'react';

const useBuildingDetailsDueDiligence = () => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    const dueDiligenceData = [
        {
            id: '1',
            category: 'Financials',
            assignment: 'Review Rent Toll',
            requestor: 'Charlie Conway',
            dueDate: 'Aug 10, 2024',
            status: 'Approved'
        },
        {
            id: '2',
            category: 'Financials',
            assignment: 'Existing Phase l Report',
            requestor: 'Dean Portman',
            dueDate: 'Aug 16, 2024',
            status: 'In Progress'
        },
        {
            id: '3',
            category: 'Financials',
            assignment: 'Schedule of CapEx',
            requestor: 'Russ Taylor',
            dueDate: 'Jul 27, 2024',
            status: 'Pending'
        },
        {
            id: '4',
            category: 'Financials',
            assignment: 'Check register for prior year',
            requestor: 'Charlie Conway',
            dueDate: 'Aug 03, 2024',
            status: 'In Progress'
        },
        {
            id: '5',
            category: 'Financials',
            assignment: 'Review tax bills for past 2 years',
            requestor: 'Russ Taylor',
            dueDate: 'Aug 15, 2024',
            status: 'Approved'
        },
        {
            id: '6',
            category: 'Financials',
            assignment: 'Schedule of tenants security',
            requestor: 'Dean Portman',
            dueDate: 'Aug 16, 2024',
            status: 'Approved'
        },
        {
            id: '7',
            category: 'Financials',
            assignment: "Current month's TI",
            requestor: 'Charlie Conway',
            dueDate: 'Aug 09, 2024',
            status: 'Pending'
        },
        {
            id: '8',
            category: 'Financials',
            assignment: 'Lucas Ln',
            requestor: 'Dean Portman',
            dueDate: 'Aug 13, 2024',
            status: 'In Progress'
        }
    ];

    const sortedData = dueDiligenceData.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }

        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }

        return 0;
    });

    const handleSortSelection = (key: any, direction: any) => {
        setSortConfig({ key, direction });
        setIsDropdownActive(false);
    };

    const toggleDropdown = () => setIsDropdownActive(!isDropdownActive);

    const getSortOptionText = () => {
        if (sortConfig.key === 'id') {
            return 'Sort by default';
        }

        if (sortConfig.key === 'assignment') {
            return sortConfig.direction === 'asc'
                ? 'Assignment Ascending'
                : 'Assignment Descending';
        }

        return sortConfig.direction === 'asc'
            ? 'Status Ascending'
            : 'Status Descending';
    };

    return {
        isDropdownActive,
        sortedData,
        toggleDropdown,
        getSortOptionText,
        handleSortSelection
    };
};

export default useBuildingDetailsDueDiligence;
