import { AxiosResponse } from 'axios';
import useError from '../../../../../../../shared/hooks/useError';
import useLoading from '../../../../../../../shared/hooks/useLoading';
import usePaneWindowCalculatorApi, {
    CalculatePaneWindowsResponse
} from './usePaneWindowCalculatorApi';
import { useState } from 'react';
import { PaneWindowForm } from '../pane-window-calculator-input/PaneWindowCalculatorInput';
import { Month } from '../../../../../../../shared/enums/Month';
import usePaneWindowCalculatorContext from '../contexts/usePaneWindowCalculatorContext';
import { PaneWindowActions } from '../state/PaneWindowActions';
import {
    BuildingWindowPaneType,
    PaneWindowAnnualType,
    PaneWindowMonthType
} from '../state/PaneWindowDefaults';
import useBuilding from '../../../../hooks/useBuilding';

const usePaneWindowCalculator = () => {
    const error = useError();
    const loading = useLoading();
    const { building } = useBuilding();

    const paneWindowCalculatorApi = usePaneWindowCalculatorApi();

    const paneWindowCalculatorContext = usePaneWindowCalculatorContext();

    const [selectedOutputDataOption, setSelectedOutputDataOption] = useState<
        Month | 'Annual'
    >('Annual');

    const handleSelectChange = (event: any) => {
        const selectedValue = event.target.value;

        if (selectedValue === 'Annual') {
            setSelectedOutputDataOption(selectedValue);
            return;
        }

        setSelectedOutputDataOption(parseInt(selectedValue));
    };

    const onSubmit = (values: PaneWindowForm) => {
        if (building === undefined) {
            return;
        }

        let paneType: BuildingWindowPaneType | null = values.paneType.value;
        let area: number | null = values.area.value;
        let thermalInsulation: number | null = values.thermalInsulation.value;
        let costOfHeatingRate: number | null = values.costOfHeatingRate.value;

        if (values.paneType.isDefaultValue) {
            paneType = null;
        }

        if (values.area.isDefaultValue) {
            area = null;
        }

        if (values.thermalInsulation.isDefaultValue) {
            thermalInsulation = null;
        }

        if (values.costOfHeatingRate.isDefaultValue) {
            costOfHeatingRate = null;
        }

        loading.load();
        paneWindowCalculatorApi
            .calculatePaneWindows(
                building.id,
                paneType,
                area,
                thermalInsulation,
                costOfHeatingRate
            )
            .then(
                (
                    response: AxiosResponse<CalculatePaneWindowsResponse, any>
                ) => {
                    const { data } = response;
                    const { monthlyMetrics, annualMetrics } = data;

                    paneWindowCalculatorContext.dispatch({
                        type: PaneWindowActions.SET_PANE_WINDOW,
                        payload: {
                            monthly: monthlyMetrics,
                            annual: annualMetrics
                        }
                    });
                }
            )
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const filterDataByOption = (
        option: Month | 'Annual'
    ): PaneWindowAnnualType | PaneWindowMonthType | undefined => {
        if (option === 'Annual') {
            return paneWindowCalculatorContext.state?.annual;
        }

        return paneWindowCalculatorContext.state?.monthly.find(
            (monthData) => monthData.month === option
        );
    };

    return {
        paneWindowCalculatorData: paneWindowCalculatorContext.state,
        selectedOutputDataOption,
        handleSelectChange,
        onSubmit,
        filterDataByOption
    };
};

export default usePaneWindowCalculator;
