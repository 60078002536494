import React from 'react';
import useBuildingDetailsDueDiligence from './hooks/useBuildingDetailsDueDiligence';
import { BsSortDown } from 'react-icons/bs';

const BuildingDetailsDueDiligence = () => {
    const {
        isDropdownActive,
        sortedData,
        toggleDropdown,
        getSortOptionText,
        handleSortSelection
    } = useBuildingDetailsDueDiligence();

    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="relative flex items-center flex-grow mb-4 text-sm">
                    <div className="text-sm">
                        <span>
                            Earnest Money:{' '}
                            <span className="text-red-500 font-semibold">
                                $25.000
                            </span>
                        </span>
                        <span className="ml-12">
                            Due Diligence Expiration:{' '}
                            <span className="text-red-500 font-semibold">
                                July 30, 2022
                            </span>
                        </span>
                        <p className="mt-2">
                            Closing Date:{' '}
                            <span className="text-red-500 font-semibold">
                                Sep 23, 2024
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    className={`relative flex flex-row items-center pr-4 pl-4 text-sm mb-4 border border-gray-300 ${isDropdownActive ? 'rounded-t-lg' : 'rounded-lg'}`}
                >
                    <BsSortDown />
                    <a
                        href="#"
                        className="pointer pl-1"
                        onClick={toggleDropdown}
                    >
                        {getSortOptionText()}
                    </a>
                    <div
                        id="dropdown"
                        className={`${!isDropdownActive && 'hidden'} z-10 absolute right-0 top-8 mt-2 w-full rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                    >
                        <div className="py-1">
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() => handleSortSelection('id', 'asc')}
                            >
                                Sort by default
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('assignment', 'asc')
                                }
                            >
                                Assignment Ascending
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('assignment', 'desc')
                                }
                            >
                                Assignment Descending
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('status', 'asc')
                                }
                            >
                                Status Ascending
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('status', 'desc')
                                }
                            >
                                Status Descending
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative overflow-x-auto border-gray-200 border rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-primary bg-gray-100">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input
                                        id="checkbox-all-search"
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                    />
                                    <label
                                        htmlFor="checkbox-all-search"
                                        className="sr-only"
                                    >
                                        checkbox
                                    </label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Category
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Assignment
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Requestor
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Due Date
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.length > 0 &&
                            sortedData.map((row, index) => (
                                <tr key={index} className="bg-white border-b">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input
                                                id="checkbox-table-search-1"
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                            />
                                            <label
                                                htmlFor="checkbox-table-search-1"
                                                className="sr-only"
                                            >
                                                checkbox
                                            </label>
                                        </div>
                                    </td>
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                    >
                                        {row.category}
                                    </th>
                                    <td className="px-6 py-4">
                                        {row.assignment}
                                    </td>
                                    <td className="px-6 py-4">
                                        {row.requestor}
                                    </td>
                                    <td className="px-6 py-4">{row.dueDate}</td>
                                    <td className="px-6 py-4 flex items-center">
                                        <div
                                            className={`h-2.5 w-2.5 rounded-full me-2 ${
                                                row.status === 'Approved'
                                                    ? 'bg-green-500'
                                                    : row.status ===
                                                        'In Progress'
                                                      ? 'bg-blue-500'
                                                      : 'bg-red-500'
                                            }`}
                                        ></div>
                                        {row.status}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default BuildingDetailsDueDiligence;
