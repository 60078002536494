import React from 'react';
import createDataContext from '../../../../shared/contexts/createDataContext';
import BuildingReducer from '../state/building/BuildingReducer';
import BuildingDefaults from '../state/building/BuildingDefaults';
import BuildingContext from './BuildingContext';

export const BuildingContextProvider = createDataContext(
    BuildingReducer,
    BuildingDefaults,
    BuildingContext
);

export default function useBuildingContext() {
    return React.useContext(BuildingContext);
}
