import React from 'react';
import createDataContext from '../../../../../shared/contexts/createDataContext';
import BuildingFiltersReducer from '../../state/building-filters/BuildingFiltersReducer';
import BuildingFiltersDefaults from '../../state/building-filters/BuildingFiltersDefaults';
import BuildingFiltersContext from './BuildingFiltersContext';

export const BuildingFiltersContextProvider = createDataContext(
    BuildingFiltersReducer,
    BuildingFiltersDefaults,
    BuildingFiltersContext
);

export default function useBuildingFiltersContext() {
    return React.useContext(BuildingFiltersContext);
}
