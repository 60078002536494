import SolarPanelCalculatorInput from './solar-panel-calculator-input/SolarPanelCalculatorInput';
import { SolarPanelCalculatorContextProvider } from './contexts/useSolarPanelCalculatorContext';
import SolarPanelCalculatorOutput from './solar-panel-calculator-output/SolarPanelCalculatorOutput';

const SolarPanelCalculator = () => {
    return (
        <SolarPanelCalculatorContextProvider>
            <SolarPanelCalculatorInput />
            <SolarPanelCalculatorOutput />
        </SolarPanelCalculatorContextProvider>
    );
};

export default SolarPanelCalculator;
