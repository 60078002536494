import React from 'react';
import createDataContext from '../../../../../shared/contexts/createDataContext';
import MapOptionsReducer from '../state/map-options/MapOptionsReducer';
import MapOptionsDefaults from '../state/map-options/MapOptionsDefaults';
import MapOptionsContext from './MapOptionsContext';

export const MapOptionsContextProvider = createDataContext(
    MapOptionsReducer,
    MapOptionsDefaults,
    MapOptionsContext
);

export default function useMapOptionsContext() {
    return React.useContext(MapOptionsContext);
}
