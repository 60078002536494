export enum BuildingType {
    SINGLE_FAMILY_RESIDENCE = 1,
    TOWN_HOUSE,
    CONDOMINIUM,
    OFFICE_BUILDING,
    MISCELLANEOUS,
    RETAIL,
    AUTO_REPAIR_GARAGE,
    ZONE,
    FINANCIAL_BUILDING
}
