import React from 'react';
import useBuildingCompare from './building-compare/hooks/useBuildingCompare';
import useBuildingRequestInfo from './building-request-info/hooks/useBuildingRequestInfo';
import BuildingCompare from './building-compare/BuildingCompare';
import BuildingRequestInfo from './building-request-info/BuildingRequestInfo';
import { FaMapMarkerAlt, FaStar } from 'react-icons/fa';
import NumberUtils from '../../../../../shared/services/numberUtils';
import { useNavigate } from 'react-router-dom';
import { Routes, RoutingKeys } from '../../../../../shared/routes/router.keys';

type BuildingCardProps = {
    id: string;
    type: string;
    image: string;
    title: string;
    price: number;
    address: string;
    isFavorite: boolean;
    toggleFavorite: () => void;
};

const BuildingCard = ({
    id,
    type,
    image,
    title,
    price,
    address,
    isFavorite,
    toggleFavorite
}: BuildingCardProps) => {
    const buildingCompare = useBuildingCompare();
    const buildingRequestInfo = useBuildingRequestInfo();
    const navigate = useNavigate();

    const navigateToBuildingDetails = (e: any) => {
        e.preventDefault();
        navigate(`${RoutingKeys[Routes.BUILDING_DETAILS]}`.replace(':id', id));
    };

    return (
        <div className="bg-white shadow-md rounded overflow-hidden mb-6">
            <div className="relative">
                <img
                    src={image}
                    alt={title}
                    className="w-full h-48 object-cover cursor-pointer"
                    onClick={navigateToBuildingDetails}
                />
                <div className="flex flex-row absolute top-3 bg-white text-primary px-2 py-1 rounded-r text-sm items-center">
                    <div className="w-2.5 h-2.5 bg-primary rounded-full mx-2"></div>
                    {type}
                </div>
                <div
                    className="bg-gray-500 bg-opacity-50 absolute top-3 right-3 p-1.5 rounded-full cursor-pointer"
                    onClick={toggleFavorite}
                >
                    <FaStar
                        className={
                            isFavorite ? 'text-yellow-400' : 'text-white'
                        }
                    />
                </div>
            </div>
            <div className="p-4">
                <div className="flex flex-row justify-between">
                    <h3
                        className="text-base font-bold cursor-pointer"
                        onClick={navigateToBuildingDetails}
                    >
                        {title}
                    </h3>
                    <div className="text-gray-700 font-semibold">
                        ${NumberUtils.formatWithCommas(price)}
                    </div>
                </div>
                <div className="text-gray-500 flex items-center mt-1 text-sm">
                    <FaMapMarkerAlt className="mr-1" />
                    {address}
                </div>
                <div className="mt-4 flex justify-around items-center border-solid border-2 border-gray-200 p-2 rounded-lg">
                    <button
                        className="font-medium text-sm"
                        onClick={() => buildingCompare.setModalValue(true)}
                    >
                        Compare
                    </button>
                    <div className="w-0.5 bg-gray-200 h-6"></div>
                    <button className="font-medium text-sm">Inspect</button>
                    <div className="w-0.5 bg-gray-200 h-6"></div>
                    <button
                        className="text-primary font-medium text-sm"
                        onClick={() => buildingRequestInfo.setModalValue(true)}
                    >
                        Request Info
                    </button>
                </div>
            </div>
            <BuildingCompare
                isOpen={buildingCompare.isModalOpen}
                onClose={() => buildingCompare.setModalValue(false)}
            />
            <BuildingRequestInfo
                isOpen={buildingRequestInfo.isModalOpen}
                onClose={() => buildingRequestInfo.setModalValue(false)}
            />
        </div>
    );
};

export default BuildingCard;
