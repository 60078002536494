export enum ApiEndpoints {
    BASE = 0,
    LOGIN,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_VERIFY,
    FORGOT_PASSWORD_RESET,
    WAITLIST,
    GET_BUILDINGS,
    CALCULATOR,
    EDIT_PROFILE,
    RESET_PASSWORD
}

export const apiEndpoints: Record<ApiEndpoints, string> = {
    [ApiEndpoints.BASE]: process.env.REACT_APP_API_BASE_ROUTE ?? '',
    [ApiEndpoints.LOGIN]: '/auth/login',
    [ApiEndpoints.FORGOT_PASSWORD]: '/auth/forgot-password',
    [ApiEndpoints.FORGOT_PASSWORD_VERIFY]: '/auth/forgot-password/verify',
    [ApiEndpoints.FORGOT_PASSWORD_RESET]: '/auth/forgot-password/reset',
    [ApiEndpoints.WAITLIST]: '/waitlist',
    [ApiEndpoints.GET_BUILDINGS]: '/buildings',
    [ApiEndpoints.CALCULATOR]: '/calculator',
    [ApiEndpoints.EDIT_PROFILE]: '/account/profile',
    [ApiEndpoints.RESET_PASSWORD]: '/account/reset-password'
};
