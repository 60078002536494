import {
    BillboardGraphics,
    Camera,
    Cartesian3,
    HeightReference,
    Scene,
    VerticalOrigin
} from 'cesium';
import { useCallback, useEffect, useState } from 'react';
import { BuildingType } from '../../state/buildings/BuildingsDefaults';
import { MAP_MAXIMUM_HEIGHT_FOR_FETCH } from '../DashboardMap';
import useGeometry from '../../hooks/useGeometry';
import useBuildings from '../../hooks/useBuildings';
import useLocationFrame from '../../hooks/useLocationFrame';

type Pin = {
    buildingId: string;
    name: string;
    position: Cartesian3;
    billboard: BillboardGraphics | BillboardGraphics.ConstructorOptions;
};

const usePins = () => {
    const [pins, setPins] = useState<Pin[]>([]);
    const geometry = useGeometry();
    const { buildings } = useBuildings();
    const locationFrame = useLocationFrame();

    const clearPins = () => {
        setPins([]);
    };

    const getPricePinCanvas = async (
        iconUrl: string,
        text: string
    ): Promise<HTMLCanvasElement> => {
        return new Promise<HTMLCanvasElement>((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;

            const icon = new Image();
            icon.crossOrigin = 'anonymous';
            icon.src = iconUrl;

            icon.onload = () => {
                ctx.save();
                ctx.fillStyle = '#1C88EC';
                ctx.strokeStyle = '#000';
                ctx.beginPath();
                ctx.roundRect(0, 0, 215, 80, 80);
                ctx.fill();
                ctx.stroke();

                ctx.fillStyle = '#fff';
                ctx.font = 'bold 42px Arial';
                ctx.fillText(text, 85, 50);

                ctx.drawImage(icon, 20, 15, 48, 48);

                ctx.restore();

                resolve(canvas);
            };

            icon.onerror = (error) => {
                reject(new Error('Failed to load image: ' + error));
            };
        });
    };

    const filterAndDisplayPins = (
        buildings: BuildingType[],
        height: number
    ): void => {
        if (height * 10000 > MAP_MAXIMUM_HEIGHT_FOR_FETCH) {
            return;
        }

        const newPins: Pin[] = [];

        for (const building of buildings) {
            getPricePinCanvas(
                'https://cdn-icons-png.flaticon.com/512/952/952772.png',
                `${building.price.value ?? 'N/A'}`
            ).then((canvas) => {
                const pin = {
                    buildingId: building.id,
                    name: building.name,
                    position: Cartesian3.fromDegrees(
                        building.location.position.longitude,
                        building.location.position.latitude
                    ),
                    billboard: {
                        image: canvas,
                        verticalOrigin: VerticalOrigin.BOTTOM,
                        heightReference: HeightReference.CLAMP_TO_GROUND,
                        height: 50,
                        width: 80
                    }
                };

                newPins.push(pin);
            });
        }

        setPins(newPins);
    };

    const onCameraMove = useCallback(
        (cam: Camera, scene: Scene) => {
            if (locationFrame == null) {
                return;
            }

            const coordinates = geometry.ellipsoidToCartesianCoordinates(
                cam,
                scene.globe.ellipsoid
            );

            if (!coordinates) {
                return;
            }

            const [northwest, northeast, southwest, southeast, height] =
                coordinates;

            if (height * 10000 > MAP_MAXIMUM_HEIGHT_FOR_FETCH) {
                locationFrame.clearLocationFrame();
                clearPins();

                return;
            }

            locationFrame.setLocationFrame(
                northwest,
                northeast,
                southwest,
                southeast
            );

            filterAndDisplayPins(buildings, height);
        },
        [buildings, locationFrame.locationFrame]
    );

    return { pins, clearPins, filterAndDisplayPins, onCameraMove };
};

export default usePins;
