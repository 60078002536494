import { useState } from 'react';
import { BuildingType } from '../../state/buildings/BuildingsDefaults';
import { DashbordItemsHeaderSortOption } from '../DashboardItems';

const useDashboardHeaderSort = () => {
    const [sortOption, setSortOption] = useState<DashbordItemsHeaderSortOption>(
        DashbordItemsHeaderSortOption.Default
    );

    const sortResults = (
        option: DashbordItemsHeaderSortOption,
        buildings: BuildingType[]
    ) => {
        let sortedList = [...buildings];

        switch (option) {
            case DashbordItemsHeaderSortOption.Default:
                sortedList.sort((a, b) => a.id.localeCompare(b.id));
                break;

            case DashbordItemsHeaderSortOption.Asc:
                sortedList.sort((a, b) => a.name.localeCompare(b.name));
                break;

            case DashbordItemsHeaderSortOption.Desc:
                sortedList.sort((a, b) => b.name.localeCompare(a.name));
                break;

            case DashbordItemsHeaderSortOption.LowestPrice:
                sortedList.sort((a, b) => a.price.value - b.price.value);
                break;

            case DashbordItemsHeaderSortOption.HighestPrice:
                sortedList.sort((a, b) => b.price.value - a.price.value);
                break;

            default:
                throw new Error('Unknown sort option');
        }

        return sortedList;
    };

    const changeSortOption = (option: DashbordItemsHeaderSortOption) => {
        setSortOption(option);
    };

    return { sortOption, changeSortOption, sortResults };
};

export default useDashboardHeaderSort;
