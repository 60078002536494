import { AxiosResponse } from 'axios';
import useError from '../../../../../../../shared/hooks/useError';
import useLoading from '../../../../../../../shared/hooks/useLoading';
import useSolarPanelCalculatorApi, {
    CalculateSolarPanelsResponse
} from './useSolarPanelCalculatorApi';
import { useState } from 'react';
import { SolarPanelForm } from '../solar-panel-calculator-input/SolarPanelCalculatorInput';
import { Month } from '../../../../../../../shared/enums/Month';
import useSolarPanelCalculatorContext from '../contexts/useSolarPanelCalculatorContext';
import { SolarPanelActions } from '../state/SolarPanelActions';
import {
    SolarPanelAnnualType,
    SolarPanelMonthType
} from '../state/SolarPanelDefaults';
import useBuilding from '../../../../hooks/useBuilding';

const useSolarPanelCalculator = () => {
    const error = useError();
    const loading = useLoading();
    const { building } = useBuilding();

    const solarPanelCalculatorApi = useSolarPanelCalculatorApi();

    const solarPanelCalculatorContext = useSolarPanelCalculatorContext();

    const [selectedOutputDataOption, setSelectedOutputDataOption] = useState<
        Month | 'Annual'
    >('Annual');

    const handleSelectChange = (event: any) => {
        const selectedValue = event.target.value;

        if (selectedValue === 'Annual') {
            setSelectedOutputDataOption(selectedValue);
            return;
        }

        setSelectedOutputDataOption(parseInt(selectedValue));
    };

    const onSubmit = (values: SolarPanelForm) => {
        if (building === undefined) {
            return;
        }

        let area: number | null = values.area.value;
        let efficiency: number | null = values.efficiency.value;
        let localElectricityRate: number | null =
            values.localElectricityRate.value;
        let numberOfPanels: number | null = values.panelsNumber.value;

        if (values.area.isDefaultValue) {
            area = null;
        }

        if (values.efficiency.isDefaultValue) {
            efficiency = null;
        }

        if (values.localElectricityRate.isDefaultValue) {
            localElectricityRate = null;
        }

        if (values.panelsNumber.isDefaultValue) {
            numberOfPanels = null;
        }

        loading.load();
        solarPanelCalculatorApi
            .calculateSolarPanels(
                building.id,
                area,
                efficiency,
                localElectricityRate,
                numberOfPanels
            )
            .then(
                (
                    response: AxiosResponse<CalculateSolarPanelsResponse, any>
                ) => {
                    const { data } = response;
                    const { monthlyMetrics, annualMetrics } = data;

                    solarPanelCalculatorContext.dispatch({
                        type: SolarPanelActions.SET_SOLAR_PANEL,
                        payload: {
                            monthly: monthlyMetrics,
                            annual: annualMetrics
                        }
                    });
                }
            )
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const filterDataByOption = (
        option: Month | 'Annual'
    ): SolarPanelAnnualType | SolarPanelMonthType | undefined => {
        if (option === 'Annual') {
            return solarPanelCalculatorContext.state?.annual;
        }

        return solarPanelCalculatorContext.state?.monthly.find(
            (monthData) => monthData.month === option
        );
    };

    return {
        solarPanelCalculatorData: solarPanelCalculatorContext.state,
        selectedOutputDataOption,
        handleSelectChange,
        onSubmit,
        filterDataByOption
    };
};

export default useSolarPanelCalculator;
