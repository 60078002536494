import React, { useState } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdLockOutline } from 'react-icons/md';

type TabProp = {
    id: number;
    title: string;
    content: React.ElementType;
};

type TabsProps = {
    data: Array<TabProp>;
};

const VerticalTabs = ({ data: tabData }: TabsProps) => {
    const [activeTab, setActiveTab] = useState(tabData[0].id);

    return (
        <div className="md:flex h-full">
            <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 md:me-4 mb-4 md:mb-0">
                {tabData.map((tab) => (
                    <li key={tab.id}>
                        <a
                            href="#"
                            onClick={() => setActiveTab(tab.id)}
                            className={`inline-flex items-center px-4 py-4 rounded-lg w-64 
                            ${
                                activeTab === tab.id
                                    ? 'text-white bg-primary'
                                    : 'bg-gray-50 hover:bg-gray-100'
                            }`}
                        >
                            {tab.title === 'Profile' ? (
                                <FaRegUserCircle
                                    className={`w-4 h-4 mr-2 ${activeTab === tab.id ? 'text-white' : 'text-gray-500'}`}
                                />
                            ) : (
                                <MdLockOutline
                                    className={`w-4 h-4 mr-2 ${activeTab === tab.id ? 'text-white' : 'text-gray-500'}`}
                                />
                            )}
                            {tab.title}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="p-6 bg-gray-50 text-medium text-gray-500 rounded-lg w-full h-full">
                {tabData.map((tab) => (
                    <div
                        key={tab.id}
                        className={activeTab === tab.id ? 'block' : 'hidden'}
                    >
                        <tab.content />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VerticalTabs;
