import React, { useState } from 'react';
import { FaCircle, FaRegQuestionCircle } from 'react-icons/fa';
import { GiBrain } from 'react-icons/gi';
import Chart from 'react-apexcharts';
import useBuildingDetailsOverview from './hooks/useBuildingDetailsOverview';
import SolarPanelCalculator from './solar-panel-calculator/SolarPanelCalculator';
import Accordion from '../../../../../shared/ui/accordion/Accordion';
import UnderfloorHeatingCalculator from './underfloor-heating-calculator/UnderfloorHeatingCalculator';
import WallInsulationCalculator from './wall-insulation-calculator/WallInsulationCalculator';
import PaneWindowCalculator from './pane-window-calculator/PaneWindowCalculator';

const BuildingDetailsOverview = () => {
    const {
        isSolarPanelsOpen,
        isPaneWindowsOpen,
        isUnderfloorHeatingOpen,
        isWallInsulationsOpen,
        chartsData,
        items,
        selectedItems,
        toggleAccordionSolarPanels,
        toggleAccordionOfPaneWindows,
        toggleUnderfloorHeatingAccordion,
        toggleAccordionOfWallInsulations,
        calculateTotalPercentage
    } = useBuildingDetailsOverview();

    return (
        <div>
            <div className="flex flex-row justify-between py-6 px-4">
                <div className="w-1/2 mr-4">
                    <p className="font-semibold text-gray-600 mb-2">
                        Predictive Calculator
                    </p>
                    <Chart
                        options={chartsData.options}
                        series={chartsData.series}
                        type="area"
                        height={350}
                    />
                    <p className="font-semibold text-gray-600 mb-2">
                        Neighborhood Growth Trends
                    </p>
                    <div className="border border-gray-200 rounded-lg p-4 text-sm">
                        <div className="flex flex-row justify-between bg-gray-300 py-4 px-6 rounded-lg mb-2 relative">
                            <div className="absolute h-full w-6 bg-pink-700 left-0 top-0 rounded-lg"></div>
                            <div className="flex flex-row items-center">
                                <FaCircle className="text-xxs ml-6 mr-2 text-pink-700" />{' '}
                                Election Change 12%
                            </div>
                            <div className="flex flex-row items-center italic">
                                Why{' '}
                                <FaRegQuestionCircle className="ml-2 text-lg" />
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-gray-300 py-4 px-6 rounded-lg mb-2 relative">
                            <div className="absolute h-full w-6 bg-emerald-700 left-0 top-0 rounded-lg"></div>
                            <div className="flex flex-row items-center">
                                <FaCircle className="text-xxs ml-6 mr-2 text-emerald-700" />{' '}
                                School Quality Increasing 16%
                            </div>
                            <div className="flex flex-row items-center italic">
                                Why{' '}
                                <FaRegQuestionCircle className="ml-2 text-lg" />
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-gray-300 py-4 px-6 rounded-lg mb-2 relative">
                            <div className="absolute h-full w-6 bg-emerald-500 left-0 top-0 rounded-lg"></div>
                            <div className="flex flex-row items-center">
                                <FaCircle className="text-xxs ml-6 mr-2 text-emerald-500" />{' '}
                                Park Redevelopment 9%
                            </div>
                            <div className="flex flex-row items-center italic">
                                Why{' '}
                                <FaRegQuestionCircle className="ml-2 text-lg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 ml-4">
                    <span className="flex flex-row items-center font-semibold text-gray-600 mb-2">
                        <GiBrain className="mr-2" /> Predictive Calculator
                    </span>
                    <div className="border border-gray-200 rounded-lg p-4 text-sm">
                        <p className="font-semibold mb-2">
                            This Property could increase to $32,546,891 in 2030
                        </p>
                        {calculateTotalPercentage() !== 0 && (
                            <p className="font-semibold text-center">
                                {calculateTotalPercentage()}% increase
                            </p>
                        )}
                        <div className="w-full bg-gray-300 rounded-lg h-7 mb-4 flex overflow-hidden">
                            {selectedItems.map((id) => {
                                const item = items.find((i) => i.id === id);
                                return (
                                    <div
                                        key={id}
                                        className={`h-full ${item?.color}`}
                                        style={{
                                            width: `${item?.percentage}%`
                                        }}
                                    ></div>
                                );
                            })}
                        </div>
                        {/* {items.map((item) => (
                            <div
                                key={item.id}
                                className="flex flex-row items-center mb-3"
                            >
                                <button
                                    className={`${item.color} w-7 h-7 rounded-lg text-white text-xl`}
                                    onClick={() => handleButtonClick(item.id)}
                                >
                                    {selectedItems.includes(item.id)
                                        ? '✓'
                                        : '+'}
                                </button>
                                <p className="ml-3">{item.label}</p>
                            </div>
                        ))} */}
                        <Accordion
                            isOpen={isSolarPanelsOpen}
                            setOpen={() => toggleAccordionSolarPanels()}
                            title={'Solar Panels'}
                        >
                            <SolarPanelCalculator />
                        </Accordion>
                        <Accordion
                            isOpen={isPaneWindowsOpen}
                            setOpen={() => toggleAccordionOfPaneWindows()}
                            title={'Pane Window'}
                        >
                            <PaneWindowCalculator />
                        </Accordion>
                        <Accordion
                            isOpen={isUnderfloorHeatingOpen}
                            setOpen={() => toggleUnderfloorHeatingAccordion()}
                            title={'Underfloor Heating'}
                        >
                            <UnderfloorHeatingCalculator />
                        </Accordion>
                        <Accordion
                            isOpen={isWallInsulationsOpen}
                            setOpen={() => toggleAccordionOfWallInsulations()}
                            title={'Wall Insulations'}
                        >
                            <WallInsulationCalculator />
                        </Accordion>
                        <div className="mt-12">
                            <button className="bg-primary rounded-lg px-4 py-2 mt-4 font-semibold text-white">
                                EXPORT AN AUTOMATED REPORT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingDetailsOverview;
