import useToggle from '../../../../../../../shared/hooks/useToggle';
import { DashbordItemsHeaderSortOption } from '../../../DashboardItems';

const useDashboardItemsHeader = () => {
    const { isActive, toggle } = useToggle();

    const onSortSelectionChange = (
        value: DashbordItemsHeaderSortOption,
        callback: any
    ) => {
        toggle();
        callback(value);
    };

    return {
        isHeaderToggleActive: isActive,
        toggleHeader: toggle,
        onSortSelectionChange
    };
};

export default useDashboardItemsHeader;
