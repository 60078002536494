import React, { useState } from 'react';

const useBuildingDetailsPhotos = () => {
    const [showAllPhotos, setShowAllPhotos] = useState(false);

    const handleSeeAllClick = () => {
        setShowAllPhotos(true);
    };

    const handleShowLessClick = () => {
        setShowAllPhotos(false);
    };

    const photos = [
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/300x200',
        'https://via.placeholder.com/300x200',
        'https://via.placeholder.com/600x400',
        'https://via.placeholder.com/300x200',
        'https://via.placeholder.com/300x200'
    ];

    const generatePhotoLayout = (photos: string[]) => {
        const layout = [];
        let i = 0;
        while (i < photos.length) {
            if (i < photos.length) {
                layout.push([photos[i]]);
                i += 1;
            }

            if (i < photos.length) {
                layout.push(photos.slice(i, i + 2));
                i += 2;
            }
        }

        return layout;
    };

    return {
        showAllPhotos,
        photos,
        handleSeeAllClick,
        handleShowLessClick,
        generatePhotoLayout
    };
};

export default useBuildingDetailsPhotos;
