import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './shared/routes/main.routes';
import { LoadingContextProvider } from './shared/contexts/loading/useLoadingContext';
import { SessionDataContextProvider } from './shared/contexts/session-data/useSessionDataContext';
import { ErrorContextProvider } from './shared/contexts/error/useErrorContext';
import { SuccessContextProvider } from './shared/contexts/success/useSuccessContext';

const App = () => {
    return (
        <LoadingContextProvider>
            <ErrorContextProvider>
                <SuccessContextProvider>
                    <SessionDataContextProvider>
                        <RouterProvider router={router} />
                    </SessionDataContextProvider>
                </SuccessContextProvider>
            </ErrorContextProvider>
        </LoadingContextProvider>
    );
};

export default App;
