import React from 'react';
import { SolarPanelType } from '../state/SolarPanelDefaults';
import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';

interface SolarPanelCalculatorContextType {
    state: SolarPanelType | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const SolarPanelCalculatorContext =
    React.createContext<SolarPanelCalculatorContextType>(
        {} as SolarPanelCalculatorContextType
    );

export default SolarPanelCalculatorContext;
