import { AxiosResponse } from 'axios';
import client from '../../../../../../../core/api/client';
import {
    ApiEndpoints,
    apiEndpoints
} from '../../../../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../../../../shared/hooks/useAxiosClient';
import {
    WallInsulationAnnualType,
    WallInsulationMonthType
} from '../state/WallInsulationDefaults';

const useWallInsulationCalculatorApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.CALCULATOR];

    const calculateWallInsulations = (
        buildingId: string,
        thermalResistanceAssembly: number[] | null,
        costOfHeatingRate: number | null
    ): Promise<AxiosResponse<CalculateWallInsulationsResponse>> => {
        const auth = axiosClient<CalculateWallInsulationsResponse>({
            apiConfig: {
                method: ApiMethod.POST,
                uri: `${endpoint}/${buildingId}/wall-insulations`,
                data: { thermalResistanceAssembly, costOfHeatingRate }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { calculateWallInsulations };
};

export type CalculateWallInsulationsResponse = {
    monthlyMetrics: Array<WallInsulationMonthType>;
    annualMetrics: WallInsulationAnnualType;
};

export default useWallInsulationCalculatorApi;
