import React from 'react';
import createDataContext from '../../../../../../../shared/contexts/createDataContext';
import UnderfloorHeatingReducer from '../state/UnderfloorHeatingReducer';
import UnderfloorHeatingDefaults from '../state/UnderfloorHeatingDefaults';
import UnderfloorHeatingCalculatorContext from './UnderfloorHeatingCalculatorContext';

export const UnderfloorHeatingCalculatorContextProvider = createDataContext(
    UnderfloorHeatingReducer,
    UnderfloorHeatingDefaults,
    UnderfloorHeatingCalculatorContext
);

export default function useUnderfloorHeatingCalculatorContext() {
    return React.useContext(UnderfloorHeatingCalculatorContext);
}
