import { useNavigate } from 'react-router-dom';
import { Routes, RoutingKeys } from '../../../../../shared/routes/router.keys';

const useLoginForm = () => {
    const navigate = useNavigate();

    const navigateToForgotPassword = (e: any) => {
        e.preventDefault();
        navigate(RoutingKeys[Routes.FORGOT_PASSWORD_EMAIL]);
    };

    return { navigateToForgotPassword };
};

export default useLoginForm;
