import { useState } from 'react';
import useDestinationLookupContext from '../../contexts/destination-lookup/useDestinationLookupContext';
import useGeocoder from '../../hooks/useGeocoder';
import {
    Cartesian3,
    Cartographic,
    GeocoderService,
    Math,
    Rectangle
} from 'cesium';
import { DestinationLookupActions } from '../../state/destination-lookup/DestinationLookupActions';

const useDashboardHeaderSearch = () => {
    const [searchDestinations, setSearchDestinations] = useState<
        GeocoderService.Result[]
    >([]);
    const [location, setLocation] = useState<string>('');

    const destinationLookupContext = useDestinationLookupContext();
    const geocoder = useGeocoder();

    const displaySearchDestination = (searchText: string): void => {
        geocoder
            .searchByAddress(searchText)
            .then((result) => {
                setSearchDestinations(result);
            })
            .catch((err) => {
                console.error('err', err);
            });
    };

    const handleDestinationClick = (
        destinationResult: GeocoderService.Result
    ) => {
        setLocation(destinationResult.displayName);

        if (destinationResult.destination instanceof Rectangle) {
            const rectangularCenter = Rectangle.center(
                destinationResult.destination
            );

            const latitude = Math.toDegrees(rectangularCenter.latitude);
            const longitude = Math.toDegrees(rectangularCenter.longitude);
            const altitude = rectangularCenter.height;

            destinationLookupContext.dispatch({
                type: DestinationLookupActions.SET_DESTINATION,
                payload: { latitude, longitude, altitude }
            });

            setSearchDestinations([]);

            return;
        }

        if (destinationResult.destination instanceof Cartesian3) {
            const cartographic = Cartographic.fromCartesian(
                destinationResult.destination
            );
            const latitude = cartographic.latitude;
            const longitude = cartographic.longitude;
            const altitude = cartographic.height;

            destinationLookupContext.dispatch({
                type: DestinationLookupActions.SET_DESTINATION,
                payload: { latitude, longitude, altitude }
            });

            setSearchDestinations([]);

            return;
        }
    };

    const clearLocation = () => {
        setLocation('');
        setSearchDestinations([]);
    };

    return {
        searchDestinations,
        location,
        displaySearchDestination,
        handleDestinationClick,
        setLocation,
        clearLocation
    };
};

export default useDashboardHeaderSearch;
