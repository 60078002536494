export enum Routes {
    DEFAULT = 0,
    LOGIN,
    FORGOT_PASSWORD_EMAIL,
    VERIFICATION,
    RESET_PASSWORD,
    REGISTER,
    WAITLIST,
    PRIVACY_POLICY,
    LEGAL_DISCLAIMER,
    DASHBOARD,
    WAITLIST_USERS,
    BUILDING_DETAILS,
    PROFILE
}

export const RoutingKeys: Record<Routes, string> = {
    [Routes.DEFAULT]: '/',
    [Routes.LOGIN]: '/login',
    [Routes.FORGOT_PASSWORD_EMAIL]: '/forgot-password-email',
    [Routes.VERIFICATION]: '/verification',
    [Routes.RESET_PASSWORD]: '/reset-password',
    [Routes.REGISTER]: '/register',
    [Routes.WAITLIST]: '/waitlist',
    [Routes.PRIVACY_POLICY]: '/privacy-policy',
    [Routes.LEGAL_DISCLAIMER]: '/legal-disclaimer',
    [Routes.DASHBOARD]: '/dashboard',
    [Routes.WAITLIST_USERS]: '/waitlist-users',
    [Routes.BUILDING_DETAILS]: '/buildings/:id',
    [Routes.PROFILE]: '/profile'
};
