import React from 'react';

type CompareLegendItemProps = {
    color: string;
    label: string;
};

const CompareLegendItem = ({ color, label }: CompareLegendItemProps) => {
    return (
        <div className="flex items-center mr-3 mb-[-1.875rem]">
            <div
                className={`w-2.5 h-2.5 mr-1.5 bg-${color}`}
                style={{ backgroundColor: color }}
            ></div>
            <span className="text-xs">{label}</span>
        </div>
    );
};

export default CompareLegendItem;
