import React from 'react';
import VerticalTabs from '../../../../shared/ui/tabs/vertical-tabs/VerticalTabs';
import EditProfile from './edit-profile/EditProfile';
import ResetPassword from './reset-password/ResetPassword';

const tabData = [
    { id: 1, title: 'Profile', content: EditProfile },
    { id: 2, title: 'Security', content: ResetPassword }
];

const ProfileTabs = () => {
    return <VerticalTabs data={tabData} />;
};

export default ProfileTabs;
