import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import wallInsulationFormSchema from './WallInsulationForm.validators';
import ControllableInput from '../../../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../../../shared/ui/buttons/button/Button';
import ControllableCheckbox from '../../../../../../../shared/ui/inputs/controllable-checkbox/ControllableCheckbox';
import useWallInsulationCalculator from '../hooks/useWallInsulationCalculator';
import { MdOutlineAddCircleOutline, MdOutlineDelete } from 'react-icons/md';

export type WallInsulationForm = {
    thermalResistanceAssembly: { value: number[]; isDefaultValue: boolean };
    costOfHeatingRate: { value: number; isDefaultValue: boolean };
};

const WallInsulationCalculatorInput = () => {
    const wallInsulationCalculator = useWallInsulationCalculator();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<WallInsulationForm>({
        resolver: yupResolver(wallInsulationFormSchema),
        defaultValues: {
            thermalResistanceAssembly: {
                value: [1],
                isDefaultValue: false
            },
            costOfHeatingRate: {
                value: 1,
                isDefaultValue: false
            }
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const {
        fields: resistanceFields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'thermalResistanceAssembly.value' as never
    });

    return (
        <div>
            <div className="flex flex-row justify-between my-6 items-center">
                <div className="w-3/5">
                    {resistanceFields.map((field, index) => (
                        <div
                            key={field.id}
                            className="flex items-center space-x-2 mb-4"
                        >
                            <div className="flex-1">
                                <ControllableInput
                                    name={`thermalResistanceAssembly.value[${index}]`}
                                    control={control}
                                    type="number"
                                    errorMessage={
                                        errors.thermalResistanceAssembly
                                            ?.value![index]?.message
                                    }
                                    required={true}
                                    disabled={watch(
                                        'thermalResistanceAssembly.isDefaultValue'
                                    )}
                                    className="w-full"
                                    labelText={
                                        index === 0
                                            ? 'Thermal Resistance Assembly'
                                            : ''
                                    }
                                />
                            </div>
                            {resistanceFields.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() =>
                                        wallInsulationCalculator.removeThermalResistanceValueAtIndex(
                                            remove,
                                            index
                                        )
                                    }
                                    className={`p-2 text-gray-500 ${index === 0 && 'mt-5'}`}
                                >
                                    <MdOutlineDelete
                                        className="hover:text-red-500"
                                        size={22}
                                    />
                                </button>
                            )}
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() =>
                            wallInsulationCalculator.addThermalResistanceValue(
                                append
                            )
                        }
                        className="flex items-center text-gray-500"
                        disabled={watch(
                            'thermalResistanceAssembly.isDefaultValue'
                        )}
                    >
                        <MdOutlineAddCircleOutline size={22} />
                        <span className="ml-2">Add new layer</span>
                    </button>
                </div>
                <ControllableCheckbox
                    name={'thermalResistanceAssembly.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.thermalResistanceAssembly?.isDefaultValue
                            ?.message
                    }
                />
            </div>
            <div className="flex flex-row justify-between mt-6 mb-12 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'costOfHeatingRate.value'}
                        control={control}
                        type="number"
                        labelText="Cost of Heating Rate"
                        errorMessage={errors.costOfHeatingRate?.value?.message}
                        required={true}
                        disabled={watch('costOfHeatingRate.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'costOfHeatingRate.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.costOfHeatingRate?.isDefaultValue?.message
                    }
                />
            </div>
            <Button
                text="CALCULATE"
                type="button"
                onClick={handleSubmit(wallInsulationCalculator.onSubmit)}
            />
        </div>
    );
};

export default WallInsulationCalculatorInput;
