import { useNavigate } from 'react-router-dom';
import {
    Routes,
    RoutingKeys
} from '../../../../../../shared/routes/router.keys';

const useBuildingDetailsHeader = () => {
    const navigate = useNavigate();

    const navigateToDashboard = (e: any) => {
        e.preventDefault();
        navigate(RoutingKeys[Routes.DASHBOARD]);
    };

    return { navigateToDashboard };
};

export default useBuildingDetailsHeader;
