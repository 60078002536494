// ControllableSelect.tsx

import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';

type OptionType = {
    value: string;
    label: string;
};

type ControllableSelectProps<T extends FieldValues> = {
    id?: string;
    name: Path<T>;
    control: Control<T>;
    labelText?: string;
    errorMessage?: string;
    required?: boolean;
    disabled?: boolean;
    options: OptionType[];
};

const ControllableSelect = <T extends FieldValues>({
    id,
    name,
    control,
    labelText,
    errorMessage,
    required,
    disabled,
    options
}: ControllableSelectProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    {labelText && (
                        <label
                            htmlFor={id}
                            className="block text-sm font-medium text-accent text-accent"
                        >
                            {labelText}
                        </label>
                    )}
                    <select
                        {...field}
                        id={id}
                        name={name}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-l-8 focus:border-l-primary"
                        disabled={disabled}
                        required={required}
                    >
                        <option value="" disabled>
                            Select an option
                        </option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {errorMessage && (
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                </>
            )}
        />
    );
};

export default ControllableSelect;
