import React from 'react';
import { Controller } from 'react-hook-form';

type InputType =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';

type ControllableInputProps = {
    id?: string;
    name: string;
    className?: string;
    type: InputType;
    placeholder?: string;
    control: any;
    labelText?: string;
    errorMessage?: string;
    required: boolean;
    disabled?: boolean;
};

const ControllableInput = ({
    id,
    name,
    type,
    placeholder,
    labelText,
    errorMessage,
    control,
    required: isRequired,
    disabled
}: ControllableInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    {labelText && (
                        <label
                            htmlFor={name}
                            className="block text-sm font-medium text-accent text-accent"
                        >
                            {labelText} {isRequired && <span>*</span>}
                        </label>
                    )}
                    <input
                        {...field}
                        type={type}
                        id={id}
                        name={name}
                        className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-l-8 focus:border-l-primary"
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    {errorMessage && (
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                </>
            )}
        />
    );
};

export default ControllableInput;
