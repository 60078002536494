import useAxiosClient, {
    ApiMethod
} from '../../../../shared/hooks/useAxiosClient';
import client from '../../../../core/api/client';
import { AuthenicatedUserType } from '../../../../state/session-data/SessionDataDefaults';
import { AxiosResponse } from 'axios';
import { ApiEndpoints, apiEndpoints } from '../../../../core/api/endpoints';

const useRegisterApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.WAITLIST];

    const register = (
        name: string,
        email: string,
        password: string
    ): Promise<AxiosResponse<AuthenticationResponse>> => {
        const auth = axiosClient<AuthenticationResponse>({
            apiConfig: {
                method: ApiMethod.POST,
                uri: `${endpoint}`,
                data: { name, email, password }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { register };
};

export type AuthenticationResponse = {
    user: AuthenicatedUserType;
};

export default useRegisterApi;
