import React from 'react';

type ButtonType = 'button' | 'submit' | 'reset';

type ButtonProps = {
    text: string;
    type: ButtonType;
    onClick: () => void;
};

const Button = ({ text, type, onClick }: ButtonProps) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className="w-full bg-primary text-white p-3 rounded-md shadow-md hover:bg-primary_hover focus:outline-none"
        >
            {text}
        </button>
    );
};

export default Button;
