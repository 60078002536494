import React from 'react';
import Chart from 'react-apexcharts';
import { IoCloseOutline } from 'react-icons/io5';
import CompareLegendItem from './components/CompareLegendItem';
import useBuildingCompare from './hooks/useBuildingCompare';

type BuildingCompareProps = {
    isOpen: boolean;
    onClose: () => void;
};

const BuildingCompare = ({ isOpen, onClose }: BuildingCompareProps) => {
    const buildingCompare = useBuildingCompare();

    const dataOverTheYears: any = {
        year2021: [882.617, 631.851, 426.444, 645.266, 767.996],
        year2022: [1102.358, 799.547, 569.66, 855.819, 956.615],
        year2023: [910.152, 640.484, 482.882, 700.208, 779.017]
    };

    const scaledDataOverTheYears = {
        year2021: dataOverTheYears.year2021.map(
            (value: number) => value / 1000
        ),
        year2022: dataOverTheYears.year2022.map(
            (value: number) => value / 1000
        ),
        year2023: dataOverTheYears.year2023.map((value: number) => value / 1000)
    };

    const chartsData = buildingCompare.getChartData([
        { key: '2021', value: scaledDataOverTheYears.year2021 },
        {
            key: '2022',
            value: scaledDataOverTheYears.year2022
        },
        {
            key: '2023',
            value: scaledDataOverTheYears.year2023
        }
    ]);

    if (!isOpen) {
        return <></>;
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-30">
            <div className="bg-white rounded-lg p-4 shadow-lg relative">
                <button
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                >
                    <IoCloseOutline className="text-black text-3xl" />
                </button>
                <div className="flex justify-center -mb-6 mt-12">
                    <CompareLegendItem color="#ed1f0c" label="Single family" />
                    <CompareLegendItem color="#787574" label="Townhouse" />
                    <CompareLegendItem color="#338771" label="Condo" />
                    <CompareLegendItem color="#b3830b" label="Semi-Detached" />
                    <CompareLegendItem
                        color="#3d0bb3"
                        label="All property types"
                    />
                </div>
                <Chart
                    options={chartsData.options}
                    series={chartsData.series}
                    type="bar"
                    height={400}
                    width={700}
                />
                <div className="flex flex-col items-end mr-3 -mt-6 mb-6">
                    <span className="text-secondary text-xs">
                        Waterloo Region
                    </span>
                    <span className="text-xs text-accent">
                        Each data point is one month of activity. Data is from
                        April 5, 2023.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default BuildingCompare;
