import React, { useEffect } from 'react';
import BuildingDetailsHeader from './header/BuildingDetailsHeader';
import BuildingDetailsCard from './card/BuildingDetailsCard';
import BuildingDetailsPanel from './panel/BuildingDetailsPanel';
import BuildingDetailsPhotos from './photos/BuildingDetailsPhotos';
import { useParams } from 'react-router-dom';
import useBuilding from '../hooks/useBuilding';

const BuildingDetailsInfo = () => {
    const { id } = useParams();
    const { fetchBuilding } = useBuilding();

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        fetchBuilding(id);
    }, []);

    return (
        <div className="h-full">
            <div className="h-1/10 border-b border-gray-300">
                <BuildingDetailsHeader />
            </div>
            <div className="h-9/10 overflow-y-auto">
                <BuildingDetailsCard />
                <BuildingDetailsPanel />
                <BuildingDetailsPhotos />
            </div>
        </div>
    );
};

export default BuildingDetailsInfo;
