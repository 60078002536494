import React from 'react';
import { IReducerAction } from '../../../../shared/models/IReducerAction';
import { WaitlistUsersType } from '../state/WaitlistUsersDefaults';

interface WaitlistUsersContextType {
    state: WaitlistUsersType[];
    dispatch: React.Dispatch<IReducerAction>;
}

const WaitlistUsersContext = React.createContext<WaitlistUsersContextType>(
    {} as WaitlistUsersContextType
);

export default WaitlistUsersContext;
