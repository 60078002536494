import { IReducerAction } from '../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../shared/types/ReducerType';
import { BuildingsActions } from './BuildingsActions';
import { BuildingType } from './BuildingsDefaults';

const BuildingsReducer: ReducerType<BuildingType[], IReducerAction> = (
    state: BuildingType[],
    action: IReducerAction
) => {
    switch (action.type) {
        case BuildingsActions.SET_BUILDINGS:
            return action.payload;
        case BuildingsActions.CLEAR_BUILDINGS:
            return [];
        default:
            return state;
    }
};

export default BuildingsReducer;
