import { AxiosResponse } from 'axios';
import { ApiEndpoints, apiEndpoints } from '../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../shared/hooks/useAxiosClient';
import client from '../../../../core/api/client';

const useWaitlistUsersApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.WAITLIST];

    const getUsers = (): Promise<AxiosResponse<WaitlistUsersResponse>> => {
        const auth = axiosClient<WaitlistUsersResponse>({
            apiConfig: {
                method: ApiMethod.GET,
                uri: `${endpoint}`,
                data: {}
            },
            axiosClientConfig: client
        });

        return auth;
    };

    const approveUser = (
        id: number
    ): Promise<AxiosResponse<WaitlistUsersResponse>> => {
        const auth = axiosClient<WaitlistUsersResponse>({
            apiConfig: {
                method: ApiMethod.PUT,
                uri: `${endpoint}/${id}/approve`,
                data: {}
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { getUsers, approveUser };
};

export type WaitlistUsersResponse = {
    id: number;
    name: string;
    email: string;
};

export default useWaitlistUsersApi;
