import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { IoOptionsOutline, IoCloseOutline } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import DashboardFilterModal from './components/dashboard-filter-modal/DashboardFilterModal';
import useDashboardFilterModal from './hooks/useDashboardFilterModal';
import useDashboardHeaderSearch from './hooks/useDashboardHeaderSearch';
import { FaCircle } from 'react-icons/fa';
import useBuildingFilters from '../hooks/useBuildingFilters';

const _ = require('lodash');

const DashboardHeader = () => {
    const dashboardFilterModal = useDashboardFilterModal();
    const dashboardHeaderSearch = useDashboardHeaderSearch();
    const { filters } = useBuildingFilters();

    const debouncedSearch = useCallback(
        _.debounce((value: string) => {
            dashboardHeaderSearch.displaySearchDestination(value);
        }, 500),
        []
    );

    const handleChange = (event: any) => {
        const { value } = event.target;
        dashboardHeaderSearch.setLocation(value);
        debouncedSearch(value);
    };

    return (
        <div className="flex items-center space-x-2 p-2 w-3/10">
            <div className="relative flex items-center flex-grow">
                <input
                    type="text"
                    className={`w-full pl-10 pr-3 py-2 border border-gray-300 focus:outline-none ${dashboardHeaderSearch.searchDestinations.length > 0 ? 'rounded-t-lg' : ' rounded-lg'}`}
                    placeholder="Search"
                    value={dashboardHeaderSearch.location}
                    onChange={handleChange}
                />
                <IoIosSearch className="absolute left-3 text-primary text-xl" />
                <button
                    className="absolute right-10 text-gray-400"
                    onClick={dashboardHeaderSearch.clearLocation}
                >
                    <IoCloseOutline className="text-primary text-2xl" />
                </button>
                <button className="absolute right-3 text-gray-400">
                    <RiRobot2Line className="text-primary text-xl" />
                </button>
                {dashboardHeaderSearch.searchDestinations.length > 0 && (
                    <ul className="absolute z-30 top-8 w-full mt-2 bg-white border border-gray-300 rounded-b-lg shadow-lg">
                        {dashboardHeaderSearch.searchDestinations.map(
                            (suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() =>
                                        dashboardHeaderSearch.handleDestinationClick(
                                            suggestion
                                        )
                                    }
                                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                                >
                                    {suggestion.displayName}
                                </li>
                            )
                        )}
                    </ul>
                )}
            </div>
            <button
                onClick={() => dashboardFilterModal.setModalValue(true)}
                className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm text-base text-primary font-medium hover:bg-gray-50 focus:outline-none"
            >
                <IoOptionsOutline className="mr-2 text-primary text-xl" />
                Filters{' '}
                {filters !== undefined && (
                    <FaCircle className="ml-0.5 text-xs p-0.5 mb-1.5" />
                )}
            </button>
            <DashboardFilterModal
                isOpen={dashboardFilterModal.isModalOpen}
                onClose={() => dashboardFilterModal.setModalValue(false)}
            />
        </div>
    );
};

export default DashboardHeader;
