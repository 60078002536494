import React, { useEffect, useRef, useState } from 'react';
import './MainLayout.css';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import {
    IoIosNotificationsOutline,
    IoIosInformationCircleOutline
} from 'react-icons/io';
import { RiHome6Line } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { MdLogout } from 'react-icons/md';
import useSessionData from '../../../hooks/useSessionData';
import NameInitialUtils from '../../../services/nameInitialUtils';
import { FaRegUser } from 'react-icons/fa';
import { Routes, RoutingKeys } from '../../../routes/router.keys';

const MainLayout = () => {
    const { logout, sessionData } = useSessionData();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);

    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const navigateToProfile = (e: any) => {
        e.preventDefault();
        setProfileDropdownOpen(false);
        navigate(RoutingKeys[Routes.PROFILE]);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setProfileDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
        <div className="h-screen w-screen">
            <div className="header h-1/12 flex items-center justify-between border-solid border-b-2 border-b-gray-200">
                <div className="flex items-center ml-4">
                    <img
                        src="/logo-black.png"
                        alt="FinSat Logo"
                        className="h-6"
                    />
                </div>
                <div className="flex items-center space-x-4 mr-6">
                    <button className="text-2xl">
                        <IoIosNotificationsOutline />
                    </button>
                    <button className="p-2 text-3xl text-primary">
                        <IoIosInformationCircleOutline />
                    </button>
                    <div ref={ref}>
                        <button
                            id="dropdownUserAvatarButton"
                            data-dropdown-toggle="dropdownAvatar"
                            className="flex justify-center p-2 bg-gray-300 rounded-full text-xs w-8 h-8 md:me-0 focus:ring-4 focus:ring-gray-300"
                            type="button"
                            onClick={toggleProfileDropdown}
                        >
                            {NameInitialUtils.getInitials(
                                `${sessionData.authenticatedUser?.firstName} ${sessionData.authenticatedUser?.lastName}`
                            )}
                        </button>
                        <div
                            id="dropdownAvatar"
                            className={`z-10 ${isProfileDropdownOpen ? '' : 'hidden'} border-gray shadow-lg absolute right-4 top-12 mt-2 bg-white bg-white divide-y divide-gray-100 rounded-lg shadow`}
                        >
                            <div className="px-4 py-3 text-sm text-gray-900">
                                <div>
                                    {sessionData.authenticatedUser?.firstName}{' '}
                                    {sessionData.authenticatedUser?.lastName}
                                </div>
                                <div className="font-medium truncate">
                                    {sessionData.authenticatedUser?.email}
                                </div>
                            </div>
                            <div className="py-2">
                                <a
                                    href="#"
                                    onClick={navigateToProfile}
                                    className="block flex items-center px-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                    <button className="p-2 text-xl text-gray-600">
                                        <FaRegUser size={20} />
                                    </button>
                                    <span>Profile</span>
                                </a>
                            </div>
                            <div className="py-2">
                                <a
                                    href="#"
                                    onClick={logout}
                                    className="block flex items-center px-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                    <button className="p-2 text-xl text-gray-600">
                                        <MdLogout />
                                    </button>
                                    <span>Sign out</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-11/12 flex flex-row">
                <div className="w-1/18 border-solid border-r-2 border-r-gray-200">
                    <nav className="min-w-max">
                        <ul className="space-y-2 text-3xl">
                            <li className="flex flex-col justify-center">
                                <NavLink
                                    to="/dashboard"
                                    className="nav-links w-full flex justify-center py-3"
                                >
                                    <RiHome6Line />
                                </NavLink>
                                <NavLink
                                    to="/waitlist-users"
                                    className="nav-links w-full flex justify-center py-3"
                                >
                                    <FiUsers />
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="w-17/18">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
