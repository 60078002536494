import * as yup from 'yup';

const schema = yup
    .object({
        paneType: yup.object({
            value: yup
                .number()
                .min(1, 'Pane Type must be greater than 0')
                .required('Pane Type is required'),
            isDefaultValue: yup.boolean().required()
        }),
        area: yup.object({
            value: yup
                .number()
                .min(1, 'Area must be greater than 0')
                .required('Area is required'),
            isDefaultValue: yup.boolean().required()
        }),
        thermalInsulation: yup.object({
            value: yup
                .number()
                .min(1, 'Thermal Insulation must be greater than 0')
                .required('Thermal Insulation is required'),
            isDefaultValue: yup.boolean().required()
        }),
        costOfHeatingRate: yup.object({
            value: yup
                .number()
                .min(1, 'Cost of Heating Rate must be greater than 0')
                .required('Cost of Heating Rate is required'),
            isDefaultValue: yup.boolean().required()
        })
    })
    .required();

export default schema;
