import React from 'react';
import createDataContext from '../../../../../shared/contexts/createDataContext';
import BuildingsReducer from '../../state/buildings/BuildingsReducer';
import BuildingsDefaults from '../../state/buildings/BuildingsDefaults';
import BuildingsContext from './BuildingsContext';

export const BuildingsContextProvider = createDataContext(
    BuildingsReducer,
    BuildingsDefaults,
    BuildingsContext
);

export default function useBuildingsContext() {
    return React.useContext(BuildingsContext);
}
