import React from 'react';
import BuildingDetailsInfo from './info/BuildingDetailsInfo';
import BuildingDetailsTabs from './tabs/BuildingDetailsTabs';
import { BuildingContextProvider } from './contexts/useBuildingContext';

const BuildingDetails = () => {
    return (
        <BuildingContextProvider>
            <div className="flex flex-row h-full">
                <div className="w-3/10">
                    <BuildingDetailsInfo />
                </div>
                <div className="relative w-7/10 h-full bg-gray-100 overflow-y-auto">
                    <BuildingDetailsTabs />
                </div>
            </div>
        </BuildingContextProvider>
    );
};

export default BuildingDetails;
