import React from 'react';
import createDataContext from '../../../../../../../shared/contexts/createDataContext';
import PaneWindowReducer from '../state/PaneWindowReducer';
import PaneWindowDefaults from '../state/PaneWindowDefaults';
import PaneWindowCalculatorContext from './PaneWindowCalculatorContext';

export const PaneWindowCalculatorContextProvider = createDataContext(
    PaneWindowReducer,
    PaneWindowDefaults,
    PaneWindowCalculatorContext
);

export default function usePaneWindowCalculatorContext() {
    return React.useContext(PaneWindowCalculatorContext);
}
