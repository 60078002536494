import * as yup from 'yup';

const schema = yup
    .object({
        area: yup.object({
            value: yup
                .number()
                .min(1, 'Area must be greater than 0')
                .required('Area is required'),
            isDefaultValue: yup.boolean().required()
        }),
        efficiency: yup.object({
            value: yup
                .number()
                .min(1, 'Efficiency must be greater than 0')
                .required('Efficiency is required'),
            isDefaultValue: yup.boolean().required()
        }),
        localElectricityRate: yup.object({
            value: yup
                .number()
                .min(1, 'Local Electricity Rate must be greater than 0')
                .required('Local Electricity Rate is required'),
            isDefaultValue: yup.boolean().required()
        }),
        panelsNumber: yup.object({
            value: yup
                .number()
                .min(1, 'Number of Panels must be greater than 0')
                .required('Number of panels is required'),
            isDefaultValue: yup.boolean().required()
        })
    })
    .required();

export default schema;
