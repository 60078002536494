import { IReducerAction } from '../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../shared/types/ReducerType';
import { BuildingFiltersActions } from './BuildingFiltersActions';
import { BuildingFilters } from './BuildingFiltersDefaults';

const BuildingFiltersReducer: ReducerType<
    BuildingFilters | undefined,
    IReducerAction
> = (state: BuildingFilters | undefined, action: IReducerAction) => {
    switch (action.type) {
        case BuildingFiltersActions.SET_BUILDING_FILTERS:
            return action.payload;
        case BuildingFiltersActions.UPDATE_BUILDING_FILTERS:
            return { ...state, ...action.payload };
        case BuildingFiltersActions.CLEAR_BUILDING_FILTERS:
            return undefined;
        default:
            return state;
    }
};

export default BuildingFiltersReducer;
