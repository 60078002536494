import { WallInsulationCalculatorContextProvider } from './contexts/useWallInsulationCalculatorContext';
import WallInsulationCalculatorInput from './wall-insulation-calculator-input/WallInsulationCalculatorInput';
import WallInsulationCalculatorOutput from './wall-insulation-calculator-output/WallInsulationCalculatorOutput';

const WallInsulationCalculator = () => {
    return (
        <WallInsulationCalculatorContextProvider>
            <WallInsulationCalculatorInput />
            <WallInsulationCalculatorOutput />
        </WallInsulationCalculatorContextProvider>
    );
};

export default WallInsulationCalculator;
