import useError from '../../../../shared/hooks/useError';
import useLoading from '../../../../shared/hooks/useLoading';
import useSuccess from '../../../../shared/hooks/useSuccess';
import useProfileApi, { EditProfileResponse } from './useProfileApi';
import { ResetPasswordForm } from '../tabs/reset-password/ResetPassword';
import useSessionData from '../../../../shared/hooks/useSessionData';
import { AxiosResponse } from 'axios';
import { EditProfileForm } from '../tabs/edit-profile/EditProfile';

const useProfile = () => {
    const error = useError();
    const success = useSuccess();
    const loading = useLoading();
    const profileApi = useProfileApi();
    const sessionData = useSessionData();

    const onEditProfile = ({ firstName, lastName, email }: EditProfileForm) => {
        loading.load();

        profileApi
            .editProfile(firstName, lastName)
            .then((response: AxiosResponse<EditProfileResponse, any>) => {
                const { data } = response;

                sessionData.updateAuthenticatedUser({
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email
                });

                success.setSuccessMessage(
                    'Your profile data has been successfully changed'
                );
            })
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const onResetPassword = ({
        currentPassword,
        newPassword,
        confirmPassword
    }: ResetPasswordForm) => {
        loading.load();

        profileApi
            .resetPassword(currentPassword, newPassword)
            .then(() => {
                success.setSuccessMessage(
                    'Your password has been successfully changed'
                );
                sessionData.logout();
            })
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    return { onEditProfile, onResetPassword };
};

export default useProfile;
