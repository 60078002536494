import {
    createWorldTerrainAsync,
    GeocoderService,
    IonGeocoderService,
    Viewer
} from 'cesium';

const useGeocoder = () => {
    const searchByAddress = async (
        searchText: string
    ): Promise<GeocoderService.Result[]> => {
        const viewer = new Viewer('pseudoCesiumContainer', {
            terrainProvider: await createWorldTerrainAsync(),
            baseLayerPicker: true,
            requestRenderMode: true,
            maximumRenderTimeChange: Infinity
        });

        const scene = viewer.scene;

        const geocoder = new IonGeocoderService({ scene });

        return geocoder.geocode(searchText);
    };

    return { searchByAddress };
};

export default useGeocoder;
