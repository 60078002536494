import { AxiosResponse } from 'axios';
import client from '../../../../../../../core/api/client';
import {
    ApiEndpoints,
    apiEndpoints
} from '../../../../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../../../../shared/hooks/useAxiosClient';
import {
    UnderfloorHeatingAnnualType,
    UnderfloorHeatingMonthType
} from '../state/UnderfloorHeatingDefaults';

const useUnderfloorHeatingCalculatorApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.CALCULATOR];

    const calculateUnderfloorHeating = (
        buildingId: string,
        heatingPower: number | null,
        thermalResistance: number | null,
        durationOfOperation: number | null
    ): Promise<AxiosResponse<CalculateUnderfloorHeatingsResponse>> => {
        const auth = axiosClient<CalculateUnderfloorHeatingsResponse>({
            apiConfig: {
                method: ApiMethod.POST,
                uri: `${endpoint}/${buildingId}/underfloor-heatings`,
                data: { heatingPower, thermalResistance, durationOfOperation }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { calculateUnderfloorHeating };
};

export type CalculateUnderfloorHeatingsResponse = {
    monthlyMetrics: Array<UnderfloorHeatingMonthType>;
    annualMetrics: UnderfloorHeatingAnnualType;
};

export default useUnderfloorHeatingCalculatorApi;
