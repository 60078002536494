import * as yup from 'yup';

const schema = yup
    .object({
        thermalResistanceAssembly: yup.object({
            value: yup
                .array(
                    yup
                        .number()
                        .min(1, 'Area must be greater than 0')
                        .required('Area is required')
                )
                .required(),
            isDefaultValue: yup.boolean().required()
        }),
        costOfHeatingRate: yup.object({
            value: yup
                .number()
                .min(1, 'Efficiency must be greater than 0')
                .required('Efficiency is required'),
            isDefaultValue: yup.boolean().required()
        })
    })
    .required();

export default schema;
