import { BuildingType } from '../enums/BuildingType';

export const buildingTypeMetadata: Record<BuildingType, string> = {
    [BuildingType.SINGLE_FAMILY_RESIDENCE]: 'Single Family Residence',
    [BuildingType.TOWN_HOUSE]: 'Town House',
    [BuildingType.CONDOMINIUM]: 'Condominium',
    [BuildingType.OFFICE_BUILDING]: 'Office Building',
    [BuildingType.MISCELLANEOUS]: 'Miscellaneous',
    [BuildingType.RETAIL]: 'Retail',
    [BuildingType.AUTO_REPAIR_GARAGE]: 'Auto Repair Garage',
    [BuildingType.ZONE]: 'Zone',
    [BuildingType.FINANCIAL_BUILDING]: 'Financial Building'
};
