import useLoadingContext from '../../../../shared/contexts/loading/useLoadingContext';
import { LoadingActions } from '../../../../state/loading/LoadingActions';
import useBuildingsApi from '../../dashboard/hooks/useBuildingsApi';
import useBuildingContext from '../contexts/useBuildingContext';
import { BuildingActions } from '../state/building/BuildingActions';

const useBuilding = () => {
    const building = useBuildingContext();
    const loading = useLoadingContext();
    const buildingsApi = useBuildingsApi();

    const fetchBuilding = (id: string): void => {
        loading.dispatch({
            type: LoadingActions.LOADING
        });

        buildingsApi
            .getById(id)
            .then((buildingResponse) => {
                const buildingData = buildingResponse.data;

                const parsedBuildingData = {
                    id: buildingData.id,
                    name: buildingData.name,
                    type: buildingData.type,
                    location: buildingData.location,
                    schematic: buildingData.schematic
                };

                building.dispatch({
                    type: BuildingActions.SET_BUILDING,
                    payload: parsedBuildingData
                });
            })
            .catch((e) => {
                console.error('ERROR FETCHING BUILDING', e);
            })
            .finally(() => {
                loading.dispatch({
                    type: LoadingActions.LOADED
                });
            });
    };

    return { building: building.state, fetchBuilding };
};

export default useBuilding;
