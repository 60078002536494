import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../../../shared/types/ReducerType';
import { SolarPanelActions } from './SolarPanelActions';
import { SolarPanelType } from './SolarPanelDefaults';

const SolarPanelReducer: ReducerType<
    SolarPanelType | undefined,
    IReducerAction
> = (state: SolarPanelType | undefined, action: IReducerAction) => {
    switch (action.type) {
        case SolarPanelActions.SET_SOLAR_PANEL:
            return {
                monthly: action.payload.monthly,
                annual: action.payload.annual
            };
        case SolarPanelActions.CLEAR_SOLAR_PANEL:
            return undefined;
        default:
            return state;
    }
};

export default SolarPanelReducer;
