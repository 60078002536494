import React from 'react';
import { BuildingType } from '../../state/buildings/BuildingsDefaults';
import { IReducerAction } from '../../../../../shared/models/IReducerAction';

interface BuildingsContextType {
    state: BuildingType[];
    dispatch: React.Dispatch<IReducerAction>;
}

const BuildingsContext = React.createContext<BuildingsContextType>(
    {} as BuildingsContextType
);

export default BuildingsContext;
