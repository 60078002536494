import React from 'react';
import { IReducerAction } from '../../../../../shared/models/IReducerAction';
import { BuildingFilters } from '../../state/building-filters/BuildingFiltersDefaults';

interface BuildingFiltersContextType {
    state: BuildingFilters | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const BuildingFiltersContext = React.createContext<BuildingFiltersContextType>(
    {} as BuildingFiltersContextType
);

export default BuildingFiltersContext;
