import React from 'react';
import { BsSortDown } from 'react-icons/bs';
import useToggle from '../../../../../../shared/hooks/useToggle';
import dashboardItemsHeaderMetadata from './DashboardItemsHeader.metadata';
import useDashboardItemsHeader from './hooks/useDashboardItemsHeader';
import { DashbordItemsHeaderSortOption } from '../../DashboardItems';

type DashboardItemsHeaderProps = {
    totalResults: number;
    sortValue: DashbordItemsHeaderSortOption | undefined;
    onSortSelection: (value: DashbordItemsHeaderSortOption) => void;
};

const DashboardItemsHeader = ({
    totalResults,
    sortValue,
    onSortSelection
}: DashboardItemsHeaderProps) => {
    const { isHeaderToggleActive, toggleHeader, onSortSelectionChange } =
        useDashboardItemsHeader();

    return (
        <div className="flex flex-row justify-between items-center bg-white sticky top-0 z-10 h-1/12 border-solid border-t-2 border-t-gray-200">
            <h2 className="pl-4">
                {totalResults}{' '}
                {totalResults === 1 ? 'Oppurtunity' : 'Oppurtunities'} found
            </h2>
            <div className="relative flex flex-row items-center pr-4 pl-1 h-full">
                <BsSortDown />
                <a href="#" className="pointer pl-1" onClick={toggleHeader}>
                    {sortValue !== undefined ? (
                        <div>{dashboardItemsHeaderMetadata[sortValue]}</div>
                    ) : (
                        <div>Sort by</div>
                    )}
                </a>
                <div
                    id="dropdown"
                    className={`${!isHeaderToggleActive && 'hidden'} absolute right-0 top-10 mt-2 w-full rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                >
                    <div className="py-1">
                        <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() =>
                                onSortSelectionChange(
                                    DashbordItemsHeaderSortOption.Default,
                                    onSortSelection
                                )
                            }
                        >
                            Sort by default
                        </a>
                        <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() =>
                                onSortSelectionChange(
                                    DashbordItemsHeaderSortOption.LowestPrice,
                                    onSortSelection
                                )
                            }
                        >
                            Price (lowest first)
                        </a>
                        <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() =>
                                onSortSelectionChange(
                                    DashbordItemsHeaderSortOption.HighestPrice,
                                    onSortSelection
                                )
                            }
                        >
                            Price (highest first)
                        </a>
                        <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() =>
                                onSortSelectionChange(
                                    DashbordItemsHeaderSortOption.Asc,
                                    onSortSelection
                                )
                            }
                        >
                            Name Ascending
                        </a>
                        <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() =>
                                onSortSelectionChange(
                                    DashbordItemsHeaderSortOption.Desc,
                                    onSortSelection
                                )
                            }
                        >
                            Name Descending
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardItemsHeader;
