import React from 'react';
import { BsSortDown } from 'react-icons/bs';
import { IoIosSearch } from 'react-icons/io';
import './BuildingDetailsContacts.css';
import useBuildingDetailsContacts from './hooks/useBuildingDetailsContacts';

const BuildingDetailsContacts = () => {
    const {
        searchQuery,
        isDropdownActive,
        sortedData,
        toggleDropdown,
        getSortOptionText,
        handleSortSelection,
        setSearchQuery
    } = useBuildingDetailsContacts();

    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="relative flex items-center flex-grow mb-4 text-sm">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search..."
                        className="rounded-lg pl-10 pr-3 py-2 border border-gray-300 focus:outline-none"
                    />
                    <IoIosSearch className="absolute left-3 text-xl" />
                </div>
                <div
                    className={`relative flex flex-row items-center pr-4 pl-4 text-sm mb-4 border border-gray-300 ${isDropdownActive ? 'rounded-t-lg' : 'rounded-lg'}`}
                >
                    <BsSortDown />
                    <a
                        href="#"
                        className="pointer pl-1"
                        onClick={toggleDropdown}
                    >
                        {getSortOptionText()}
                    </a>
                    <div
                        id="dropdown"
                        className={`${!isDropdownActive && 'hidden'} z-10 absolute right-0 top-7 mt-2 w-full rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                    >
                        <div className="py-1">
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() => handleSortSelection('id', 'asc')}
                            >
                                Sort by default
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('fullName', 'asc')
                                }
                            >
                                Name Ascending
                            </a>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700"
                                onClick={() =>
                                    handleSortSelection('fullName', 'desc')
                                }
                            >
                                Name Descending
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative overflow-x-auto border-gray-200 border rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-primary bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-4">
                                Full Name
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Address
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Job Title
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Phone Number
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Email
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.length > 0 ? (
                            sortedData.map((row, index) => (
                                <tr key={index} className="bg-white border-b">
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                    >
                                        {row.fullName}
                                    </th>
                                    <td className="px-6 py-4">{row.address}</td>
                                    <td className="px-6 py-4">
                                        {row.jobTitle}
                                    </td>
                                    <td className="px-6 py-4">
                                        {row.phoneNumber}
                                    </td>
                                    <td className="px-6 py-4">{row.email}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={5}
                                    className="px-6 py-4 text-center"
                                >
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default BuildingDetailsContacts;
