import React, { useEffect } from 'react';
import useWaitlistUsers from './hooks/useWaitlistUsers';
import { IoIosSearch } from 'react-icons/io';

const WaitlistUsers = () => {
    const {
        waitlistUsers,
        searchQuery,
        onApprove,
        onGetUsers,
        setSearchQuery,
        searchFilter
    } = useWaitlistUsers();

    useEffect(() => {
        onGetUsers();
    }, []);

    return (
        <>
            <div className="mx-12 my-8">
                <h1 className="text-2xl font-semibold">Waitlist Users</h1>
            </div>
            <div className="relative flex items-center flex-grow mb-4 text-sm mx-12">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    className="rounded-lg pl-10 pr-3 py-2 border border-gray-300 focus:outline-none"
                />
                <IoIosSearch className="absolute left-3 text-xl" />
            </div>
            <div className="relative overflow-x-auto border-gray-200 border rounded-lg m-12 mt-8">
                <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-primary bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-4">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-4">
                                Approved
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {waitlistUsers.filter(searchFilter).length > 0 ? (
                            waitlistUsers
                                .filter(searchFilter)
                                .map((row, index) => (
                                    <tr
                                        key={index}
                                        className="bg-white border-b"
                                    >
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                        >
                                            {row.name}
                                        </th>
                                        <td className="px-6 py-4">
                                            {row.email}
                                        </td>
                                        <td className="px-6 py-4">
                                            <button
                                                className="text-white bg-primary hover:bg-blue-700 font-bold py-2 px-4 rounded"
                                                onClick={() =>
                                                    onApprove(row.id)
                                                }
                                            >
                                                Approve
                                            </button>
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={5}
                                    className="px-6 py-4 text-center"
                                >
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default WaitlistUsers;
