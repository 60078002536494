import PaneWindowCalculatorInput from './pane-window-calculator-input/PaneWindowCalculatorInput';
import { PaneWindowCalculatorContextProvider } from './contexts/usePaneWindowCalculatorContext';
import PaneWindowCalculatorOutput from './pane-window-calculator-output/PaneWindowCalculatorOutput';

const PaneWindowCalculator = () => {
    return (
        <PaneWindowCalculatorContextProvider>
            <PaneWindowCalculatorInput />
            <PaneWindowCalculatorOutput />
        </PaneWindowCalculatorContextProvider>
    );
};

export default PaneWindowCalculator;
