import React from 'react';
import DashboardMap from './dashboard-map/DashboardMap';
import DashboardItems from './dashboard-items/DashboardItems';
import DashboardHeader from './dashboard-header/DashboardHeader';
import { BuildingsContextProvider } from './contexts/buildings/useBuildingsContext';
import { MapOptionsContextProvider } from './dashboard-map/contexts/useMapOptionsContext';
import { UserLocationContextProvider } from './dashboard-map/contexts/useUserLocationContext';
import { DestinationLookupContextProvider } from './contexts/destination-lookup/useDestinationLookupContext';
import { LocationFrameContextProvider } from './contexts/location-frame/useLocationFrameContext';
import { BuildingFiltersContextProvider } from './contexts/building-filters/useBuildingFiltersContext';

const Dashboard = () => {
    return (
        <BuildingsContextProvider>
            <BuildingFiltersContextProvider>
                <LocationFrameContextProvider>
                    <DestinationLookupContextProvider>
                        <canvas
                            id="pseudoCesiumContainer"
                            className="w-32 h-32 hidden"
                        ></canvas>
                        <div className="h-full w-full">
                            <div className="h-1/12">
                                <DashboardHeader />
                            </div>
                            <div className="h-11/12 flex flex-row">
                                <div className="w-3/10">
                                    <DashboardItems />
                                </div>
                                <div className="w-7/10">
                                    <MapOptionsContextProvider>
                                        <UserLocationContextProvider>
                                            <DashboardMap />
                                        </UserLocationContextProvider>
                                    </MapOptionsContextProvider>
                                </div>
                            </div>
                        </div>
                    </DestinationLookupContextProvider>
                </LocationFrameContextProvider>
            </BuildingFiltersContextProvider>
        </BuildingsContextProvider>
    );
};

export default Dashboard;
