import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import solarPanelFormSchema from './UnderfloorHeating.validators';
import ControllableInput from '../../../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../../../shared/ui/buttons/button/Button';
import ControllableCheckbox from '../../../../../../../shared/ui/inputs/controllable-checkbox/ControllableCheckbox';
import useUnderfloorHeatingCalculator from '../hooks/useUnderfloorHeatingCalculator';

export type UnderfloorHeatingForm = {
    heatingPower: { value: number; isDefaultValue: boolean };
    thermalResistance: { value: number; isDefaultValue: boolean };
    durationOfOperation: { value: number; isDefaultValue: boolean };
};

const UnderfloorHeatingCalculatorInput = () => {
    const underfloorHeatingCalculator = useUnderfloorHeatingCalculator();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<UnderfloorHeatingForm>({
        resolver: yupResolver(solarPanelFormSchema),
        defaultValues: {
            heatingPower: {
                value: 1,
                isDefaultValue: false
            },
            thermalResistance: {
                value: 1,
                isDefaultValue: false
            },
            durationOfOperation: {
                value: 1,
                isDefaultValue: false
            }
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    return (
        <div>
            <div className="flex flex-row justify-between my-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'heatingPower.value'}
                        control={control}
                        type="number"
                        labelText="Heating Power"
                        errorMessage={errors.heatingPower?.value?.message}
                        required={true}
                        disabled={watch('heatingPower.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'heatingPower.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.heatingPower?.isDefaultValue?.message}
                />
            </div>
            <div className="flex flex-row justify-between mt-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'thermalResistance.value'}
                        control={control}
                        type="number"
                        labelText="Thermal Resistance"
                        errorMessage={errors.thermalResistance?.value?.message}
                        required={true}
                        disabled={watch('thermalResistance.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'thermalResistance.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.thermalResistance?.isDefaultValue?.message
                    }
                />
            </div>
            <div className="flex flex-row justify-between mt-6 mb-12 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'durationOfOperation.value'}
                        control={control}
                        type="number"
                        labelText="Duration of Operation"
                        errorMessage={
                            errors.durationOfOperation?.value?.message
                        }
                        required={true}
                        disabled={watch('durationOfOperation.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'durationOfOperation.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.durationOfOperation?.isDefaultValue?.message
                    }
                />
            </div>
            <Button
                text="CALCULATE"
                type="button"
                onClick={handleSubmit(underfloorHeatingCalculator.onSubmit)}
            />
        </div>
    );
};

export default UnderfloorHeatingCalculatorInput;
