export type BuildingFiltersPriceRange = {
    minimum: number;
    maximum: number;
};

export type BuildingFilters = {
    priceRange: BuildingFiltersPriceRange;
    type: number[] | undefined;
};

const BuildingFiltersDefaults: BuildingFilters | undefined = undefined;

export default BuildingFiltersDefaults;
