import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../../../shared/types/ReducerType';
import { UnderfloorHeatingActions } from './UnderfloorHeatingActions';
import { UnderfloorHeatingType } from './UnderfloorHeatingDefaults';

const UnderfloorHeatingReducer: ReducerType<
    UnderfloorHeatingType | undefined,
    IReducerAction
> = (state: UnderfloorHeatingType | undefined, action: IReducerAction) => {
    switch (action.type) {
        case UnderfloorHeatingActions.SET_UNDERFLOOR_HEATING:
            return {
                monthly: action.payload.monthly,
                annual: action.payload.annual
            };
        case UnderfloorHeatingActions.CLEAR_UNDERFLOOR_HEATING:
            return undefined;
        default:
            return state;
    }
};

export default UnderfloorHeatingReducer;
