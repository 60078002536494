import React, { useEffect, useState } from 'react';
import useBuildings from '../hooks/useBuildings';
import useLocationFrame from '../hooks/useLocationFrame';
import useGeometry from '../hooks/useGeometry';
import DashboardItemsHeader from './components/dashboard-items-header/DashboardItemsHeader';
import useDashboardHeaderSort from './hooks/useDashboardHeaderSort';
import BuildingCard from './building-card/BuildingCard';
import useBuildingFilters from '../hooks/useBuildingFilters';

export enum DashbordItemsHeaderSortOption {
    Default = 0,
    Asc,
    Desc,
    HighestPrice,
    LowestPrice
}

const DashboardItems = () => {
    const [favorites, setFavorites] = useState<{ [key: string]: boolean }>({});
    const [parsedBuildings, setParsedBuildings] = useState<any>([]);

    const { buildings, fetchBuildings } = useBuildings();
    const { filters: buildingFilters, filterBuildings } = useBuildingFilters();
    const { sortOption, changeSortOption, sortResults } =
        useDashboardHeaderSort();
    const locationFrame = useLocationFrame();
    const geometry = useGeometry();

    const toggleFavorite = (id: string) => {
        setFavorites((prevFavorites) => ({
            ...prevFavorites,
            [id]: !prevFavorites[id]
        }));
    };

    useEffect(() => {
        if (locationFrame.locationFrame === undefined) {
            return;
        }

        fetchBuildings(
            locationFrame.locationFrame,
            undefined,
            buildingFilters?.type
        );
    }, [locationFrame.locationFrame, buildingFilters]);

    const fallbackImages = [
        'https://www.sherlock-holmes.co.uk/wp-content/uploads/2020/02/museum-crop-scaled-e1680133668502.jpg',
        'https://degxmlob9084d.cloudfront.net/97ec330f1995807331d2f1e2afa4ec6de8b9684c-1-medium.jpeg',
        'https://www.compass.com/m/2f47fd61f8f05b5444750fdb9931f4b1c51c058e_img_0_0b2e2/640x480.jpg',
        'https://photos.zillowstatic.com/fp/8a4ee196685a75b5b4f2f9ac266bfaf6-p_e.jpg',
        'https://www.compass.com/m/c11abfc474d34b4691c30ad3a388ce54f80060df_img_0_ec06d/640x480.jpg'
    ];

    const getRandomImage = () => {
        return fallbackImages[
            Math.floor(Math.random() * fallbackImages.length)
        ];
    };

    return (
        <>
            {buildings.length === 0 && (
                <div className="flex flex-row justify-center p-4 bg-white sticky top-0 z-10 h-1/12 items-center border-solid border-t-2 border-t-gray-200">
                    No data
                </div>
            )}
            {buildings.length > 0 && (
                <>
                    <DashboardItemsHeader
                        totalResults={buildings.length}
                        sortValue={sortOption}
                        onSortSelection={(
                            option: DashbordItemsHeaderSortOption
                        ) => {
                            changeSortOption(option);
                            const sortedBuildings = sortResults(
                                option,
                                buildings
                            );
                            setParsedBuildings(sortedBuildings);
                        }}
                    />
                    <div className="mx-auto p-4 overflow-y-auto bg-gray-100 h-11/12">
                        {buildings.map((building: any) => (
                            <BuildingCard
                                key={building.id}
                                id={building.id}
                                type={'Fantastic opportunity'}
                                image={building.imageUrl || getRandomImage()}
                                title={building.name}
                                price={building?.price?.value}
                                address={building.location.streetName}
                                isFavorite={!!favorites[building.id]}
                                toggleFavorite={() =>
                                    toggleFavorite(building.id)
                                }
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default DashboardItems;
