export enum MapRenderType {
    PHOTOREALISTIC = 0,
    NORMAL
}

export enum MapPerspective {
    TWO_DIMENSION = 0,
    THREE_DIMENSION
}

export enum MapTerrainType {
    SATELLITE = 0,
    STREET
}

export type MapOptionsType = {
    renderType: MapRenderType;
    perspective: MapPerspective;
    terrainType: MapTerrainType;
};

const MapOptions: MapOptionsType = {
    renderType: MapRenderType.NORMAL,
    perspective: MapPerspective.TWO_DIMENSION,
    terrainType: MapTerrainType.STREET
};

export default MapOptions;
