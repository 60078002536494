import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import solarPanelFormSchema from './SolarPanelForm.validators';
import ControllableInput from '../../../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../../../shared/ui/buttons/button/Button';
import ControllableCheckbox from '../../../../../../../shared/ui/inputs/controllable-checkbox/ControllableCheckbox';
import useSolarPanelCalculator from '../hooks/useSolarPanelCalculator';

export type SolarPanelForm = {
    area: { value: number; isDefaultValue: boolean };
    efficiency: { value: number; isDefaultValue: boolean };
    localElectricityRate: { value: number; isDefaultValue: boolean };
    panelsNumber: { value: number; isDefaultValue: boolean };
};

const SolarPanelCalculatorInput = () => {
    const solarPanelCalculator = useSolarPanelCalculator();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<SolarPanelForm>({
        resolver: yupResolver(solarPanelFormSchema),
        defaultValues: {
            area: {
                value: 1,
                isDefaultValue: false
            },
            efficiency: {
                value: 1,
                isDefaultValue: false
            },
            localElectricityRate: {
                value: 1,
                isDefaultValue: false
            },
            panelsNumber: {
                value: 1,
                isDefaultValue: false
            }
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    return (
        <div>
            <div className="flex flex-row justify-between my-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'area.value'}
                        control={control}
                        type="number"
                        labelText="Area"
                        errorMessage={errors.area?.value?.message}
                        required={true}
                        disabled={watch('area.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'area.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.area?.isDefaultValue?.message}
                />
            </div>
            <div className="flex flex-row justify-between mt-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'efficiency.value'}
                        control={control}
                        type="number"
                        labelText="Efficiency"
                        errorMessage={errors.efficiency?.value?.message}
                        required={true}
                        disabled={watch('efficiency.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'efficiency.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.efficiency?.isDefaultValue?.message}
                />
            </div>
            <div className="flex flex-row justify-between mt-6 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'localElectricityRate.value'}
                        control={control}
                        type="number"
                        labelText="Local Electricity Rate"
                        errorMessage={
                            errors.localElectricityRate?.value?.message
                        }
                        required={true}
                        disabled={watch('localElectricityRate.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'localElectricityRate.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={
                        errors.localElectricityRate?.isDefaultValue?.message
                    }
                />
            </div>
            <div className="flex flex-row justify-between mt-6 mb-12 items-center">
                <div className="w-3/5">
                    <ControllableInput
                        name={'panelsNumber.value'}
                        control={control}
                        type="number"
                        labelText="Number of Panels"
                        errorMessage={errors.panelsNumber?.value?.message}
                        required={true}
                        disabled={watch('panelsNumber.isDefaultValue')}
                    />
                </div>
                <ControllableCheckbox
                    name={'panelsNumber.isDefaultValue'}
                    control={control}
                    labelText="Set as default"
                    errorMessage={errors.panelsNumber?.isDefaultValue?.message}
                />
            </div>
            <Button
                text="CALCULATE"
                type="button"
                onClick={handleSubmit(solarPanelCalculator.onSubmit)}
            />
        </div>
    );
};

export default SolarPanelCalculatorInput;
