import useRegisterApi from './useRegisterApi';
import { useNavigate } from 'react-router-dom';
import { Routes, RoutingKeys } from '../../../../shared/routes/router.keys';
import useLoading from '../../../../shared/hooks/useLoading';
import useError from '../../../../shared/hooks/useError';

const useRegister = () => {
    const error = useError();

    const registerApi = useRegisterApi();
    const navigate = useNavigate();

    const loading = useLoading();

    const onSubmit = (name: string, email: string, password: string) => {
        loading.load();

        registerApi
            .register(name, email, password)
            .then(() => {
                setTimeout(() => {
                    navigate(RoutingKeys[Routes.WAITLIST]);
                }, 500);
            })
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const navigateToLogin = (e: any) => {
        e.preventDefault();
        navigate(RoutingKeys[Routes.LOGIN]);
    };

    return { onSubmit, navigateToLogin };
};

export default useRegister;
