import { AxiosResponse } from 'axios';
import client from '../../../../../../../core/api/client';
import {
    ApiEndpoints,
    apiEndpoints
} from '../../../../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../../../../shared/hooks/useAxiosClient';
import {
    SolarPanelAnnualType,
    SolarPanelMonthType
} from '../state/SolarPanelDefaults';

const useSolarPanelCalculatorApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.CALCULATOR];

    const calculateSolarPanels = (
        buildingId: string,
        area: number | null,
        efficiency: number | null,
        localElectricityRate: number | null,
        panelsNumber: number | null
    ): Promise<AxiosResponse<CalculateSolarPanelsResponse>> => {
        const auth = axiosClient<CalculateSolarPanelsResponse>({
            apiConfig: {
                method: ApiMethod.POST,
                uri: `${endpoint}/${buildingId}/solar-panels`,
                data: { area, efficiency, localElectricityRate, panelsNumber }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { calculateSolarPanels };
};

export type CalculateSolarPanelsResponse = {
    monthlyMetrics: Array<SolarPanelMonthType>;
    annualMetrics: SolarPanelAnnualType;
};

export default useSolarPanelCalculatorApi;
