import React from 'react';
import useWallInsulationCalculator from '../hooks/useWallInsulationCalculator';

const WallInsulationCalculatorOutput = () => {
    const {
        selectedOutputDataOption,
        wallInsulationCalculatorData,
        handleSelectChange,
        filterDataByOption
    } = useWallInsulationCalculator();

    if (wallInsulationCalculatorData === undefined) {
        return <></>;
    }

    const data = filterDataByOption(selectedOutputDataOption);

    return (
        <div className="py-10">
            <div className="mb-6">
                <select
                    className="block w-1/2 p-2.5 border border-gray-300 rounded-md custom-select"
                    value={selectedOutputDataOption}
                    onChange={handleSelectChange}
                >
                    <option value="Annual">Annual</option>
                    {wallInsulationCalculatorData.monthly.map(
                        (monthData, index) => (
                            <option key={index} value={monthData.month}>
                                {monthData.month}
                            </option>
                        )
                    )}
                </select>
            </div>
            {data === undefined && <>No Data for this filter</>}
            {data !== undefined && (
                <div>
                    <h3 className="text-lg">
                        {selectedOutputDataOption === 'Annual'
                            ? 'Annual Details'
                            : `${(data as any).month} Details`}
                    </h3>
                    <div className="flex justify-between my-1 items-center">
                        <span className="w-1/2 font-medium text-accent">
                            Heat Transfer Coefficient:
                        </span>
                        <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                            {data.heatTransferCoefficient}
                        </span>
                    </div>
                    <div className="flex justify-between my-1 items-center">
                        <span className="w-1/2 font-medium text-accent">
                            Heat Loss:
                        </span>
                        <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                            {data.heatLoss}
                        </span>
                    </div>
                    <div className="flex justify-between my-1 items-center">
                        <span className="w-1/2 font-medium text-accent">
                            Energy Savings:
                        </span>
                        <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                            {data.energySavings}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WallInsulationCalculatorOutput;
