import { useState } from 'react';
import useToggle from '../../../../../../../shared/hooks/useToggle';

const useBuildingRequestInfo = () => {
    const { isActive: isModalActive, setToggle } = useToggle();

    const setModalValue = (value: boolean) => {
        setToggle(value);
    };

    return { isModalOpen: isModalActive, setModalValue };
};

export default useBuildingRequestInfo;
