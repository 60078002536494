import React from 'react';
import { DetailedBuildingType } from '../state/building/BuildingDefaults';
import { IReducerAction } from '../../../../shared/models/IReducerAction';

interface BuildingContextType {
    state: DetailedBuildingType | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const BuildingContext = React.createContext<BuildingContextType>(
    {} as BuildingContextType
);

export default BuildingContext;
