import React from 'react';
import createDataContext from '../../../../../../../shared/contexts/createDataContext';
import SolarPanelReducer from '../state/SolarPanelReducer';
import SolarPanelDefaults from '../state/SolarPanelDefaults';
import SolarPanelCalculatorContext from './SolarPanelCalculatorContext';

export const SolarPanelCalculatorContextProvider = createDataContext(
    SolarPanelReducer,
    SolarPanelDefaults,
    SolarPanelCalculatorContext
);

export default function useSolarPanelCalculatorContext() {
    return React.useContext(SolarPanelCalculatorContext);
}
