import React from 'react';
import DefaultTabs from '../../../../shared/ui/tabs/default-tabs/DefaultTabs';
import BuildingDetailsOverview from './overview/BuildingDetailsOverview';
import BuildingDetailsMarketplace from './marketplace/BuildingDetailsMarketplace';
import BuildingDetailsNeighborhood from './neighborhood/BuildingDetailsNeighborhood';
import BuildingDetailsFinancial from './financial/BuildingDetailsFinancial';
import BuildingDetailsClimateChange from './climate-change/BuildingDetailsClimateChange';
import BuildingDetailsRedevelopment from './redevelopment/BuildingDetailsRedevelopment';
import BuildingDetailsDueDiligence from './due-diligence/BuildingDetailsDueDiligence';
import BuildingDetailsContacts from './contacts/BuildingDetailsContacts';

const tabData = [
    { id: 1, title: 'Overview', content: BuildingDetailsOverview },
    // { id: 2, title: 'Marketplace', content: BuildingDetailsMarketplace },
    { id: 3, title: 'Neighborhood', content: BuildingDetailsNeighborhood },
    { id: 4, title: 'Financial', content: BuildingDetailsFinancial },
    { id: 5, title: 'Climate Change', content: BuildingDetailsClimateChange },
    { id: 6, title: 'Redevelopment', content: BuildingDetailsRedevelopment },
    { id: 7, title: 'Due Diligence', content: BuildingDetailsDueDiligence },
    { id: 8, title: 'Contacts', content: BuildingDetailsContacts }
];

const BuildingDetailsTabs = () => {
    return (
        <div>
            <DefaultTabs data={tabData} />
        </div>
    );
};

export default BuildingDetailsTabs;
