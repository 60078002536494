import { Month } from '../../../../../shared/enums/Month';
import { BuildingWindowPaneType } from './pane-window-calculator/state/PaneWindowDefaults';

export const annualAndMonthMetadata: Record<Month | -1, string> = {
    [-1]: 'Annual',
    [Month.JANUARY]: 'January',
    [Month.FEBRUARY]: 'February',
    [Month.MARCH]: 'March',
    [Month.APRIL]: 'April',
    [Month.MAY]: 'May',
    [Month.JUNE]: 'June',
    [Month.JULY]: 'July',
    [Month.AUGUST]: 'August',
    [Month.SEPTEMBER]: 'September',
    [Month.OCTOBER]: 'October',
    [Month.NOVEMBER]: 'November',
    [Month.DECEMBER]: 'December'
};

export const buildingWindowPaneMetadata: Record<
    BuildingWindowPaneType,
    string
> = {
    [BuildingWindowPaneType.SINGLE_PANE]: 'Single Pane',
    [BuildingWindowPaneType.DOUBLE_PANE]: 'Double Pane',
    [BuildingWindowPaneType.TRIPLE_PANE]: 'Triple Pane'
};
