import { Cartesian3 } from 'cesium';
import useMapOptionsContext from '../contexts/useMapOptionsContext';
import useUserLocationContext from '../contexts/useUserLocationContext';
import { MapOptionsActions } from '../state/map-options/MapOptionsActions';
import {
    MapPerspective,
    MapRenderType,
    MapTerrainType
} from '../state/map-options/MapOptionsDefaults';
import { UserLocationActions } from '../state/user-location/UserLocationActions';

const ZOOM_FACTOR = 0.3;

const useDashboardMap = () => {
    const mapOptionsContext = useMapOptionsContext();
    const userLocationContext = useUserLocationContext();

    const activateUserLocation = (viewerRef: any) => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by this browser.');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const {
                    coords: { latitude, longitude }
                } = position;

                userLocationContext.dispatch({
                    type: UserLocationActions.SET_LOCATION,
                    payload: { latitude, longitude }
                });

                moveCameraToLocation(viewerRef, latitude, longitude);
            },
            () => {
                alert('Allow permissions.');
            }
        );
    };

    const deactivateUserLocation = () => {
        userLocationContext.dispatch({
            type: UserLocationActions.CLEAR_LOCATION
        });
    };

    const moveCameraToLocation = (
        viewerRef: any,
        latitude: number,
        longitude: number,
        duration: number = 2
    ) => {
        const cartesianPosition = Cartesian3.fromDegrees(
            longitude,
            latitude,
            2000
        );

        const viewer = viewerRef.current.cesiumElement;
        viewer.camera.flyTo({
            destination: cartesianPosition,
            duration
        });
    };

    const zoomIn = (viewerRef: any) => {
        const viewer = viewerRef.current.cesiumElement;
        const camera = viewer.camera;
        camera.zoomIn(camera.positionCartographic.height * ZOOM_FACTOR);
    };

    const zoomOut = (viewerRef: any) => {
        const viewer = viewerRef.current.cesiumElement;
        const camera = viewer.camera;
        camera.zoomOut(camera.positionCartographic.height * ZOOM_FACTOR);
    };

    const setPhotorealistic = (): void => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                renderType: MapRenderType.PHOTOREALISTIC,
                perspective: MapPerspective.THREE_DIMENSION,
                terrainType: MapTerrainType.STREET
            }
        });
    };

    const setNormal = (): void => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                renderType: MapRenderType.NORMAL,
                perspective: MapPerspective.TWO_DIMENSION,
                terrainType: MapTerrainType.SATELLITE
            }
        });
    };

    const setTwoDimensional = () => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                perspective: MapPerspective.TWO_DIMENSION
            }
        });
    };

    const setThreeDimensional = () => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                perspective: MapPerspective.THREE_DIMENSION
            }
        });
    };

    const setStreet = () => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                terrainType: MapTerrainType.STREET
            }
        });
    };

    const setSatellite = () => {
        mapOptionsContext.dispatch({
            type: MapOptionsActions.SET_OPTIONS,
            payload: {
                terrainType: MapTerrainType.SATELLITE
            }
        });
    };

    return {
        mapOptions: mapOptionsContext.state,
        userLocation: userLocationContext.state,
        setPhotorealistic,
        setNormal,
        setTwoDimensional,
        setThreeDimensional,
        setStreet,
        setSatellite,
        zoomIn,
        zoomOut,
        activateUserLocation,
        deactivateUserLocation,
        moveCameraToLocation
    };
};

export default useDashboardMap;
