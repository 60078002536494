import React, { useState } from 'react';

const useBuildingDetailsContacts = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    const contactsData = [
        {
            id: '1',
            fullName: 'Michael Page',
            address: '1 Saddleridge Rd',
            jobTitle: 'Property Manager',
            phoneNumber: '+33 1 40 14 00 72',
            email: 'michel.123@hotmail.com'
        },
        {
            id: '2',
            fullName: 'Mona Jaafil',
            address: 'Edge 12 Hill Rd',
            jobTitle: 'Compliance Specialist',
            phoneNumber: '+33 1 11 17 09 50',
            email: 'monamona@hotmail.com'
        },
        {
            id: '3',
            fullName: 'Luis Gabaldon',
            address: 'Minnisink Rd',
            jobTitle: 'Property Accountant',
            phoneNumber: '+33 1 89 14 01 33',
            email: 'luisG@hotmail.com'
        },
        {
            id: '4',
            fullName: 'Taylor Styduhar',
            address: '48 bd de la Bastille',
            jobTitle: 'Mortgage Loan Office',
            phoneNumber: '+33 1 47 14 07 77',
            email: 'Sty.Taylor@hotmail.com'
        },
        {
            id: '5',
            fullName: 'Dean Lane',
            address: '1 Latene Rd',
            jobTitle: 'Real Estate Appraiser',
            phoneNumber: '+33 1 40 24 80 54',
            email: 'dean.dean@hotmail.com'
        },
        {
            id: '6',
            fullName: 'Lisa John',
            address: 'Rue de Rivoli',
            jobTitle: 'Compliance Specialist',
            phoneNumber: '+33 1 15 14 38 72',
            email: 'lissa1@hotmail.com'
        },
        {
            id: '7',
            fullName: 'Selena Arfah',
            address: 'Hemlock Ave',
            jobTitle: 'Escrow Officer',
            phoneNumber: '+33 1 40 14 65 64',
            email: 'SelenaA@hotmail.com'
        },
        {
            id: '8',
            fullName: 'Jimmy Cruz',
            address: 'Lucas Ln',
            jobTitle: 'Real Estate Agent',
            phoneNumber: '+33 1 44 14 40 78',
            email: 'JimmyCR@hotmail.com'
        }
    ];

    const filteredData = contactsData.filter(
        (row) =>
            row.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.jobTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedData = filteredData.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }

        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }

        return 0;
    });

    const handleSortSelection = (key: any, direction: any) => {
        setSortConfig({ key, direction });
        setIsDropdownActive(false);
    };

    const toggleDropdown = () => setIsDropdownActive(!isDropdownActive);

    const getSortOptionText = () => {
        if (sortConfig.key === 'id') {
            return 'Sort by default';
        }

        return sortConfig.direction === 'asc'
            ? 'Name Ascending'
            : 'Name Descending';
    };

    return {
        searchQuery,
        isDropdownActive,
        sortedData,
        toggleDropdown,
        getSortOptionText,
        handleSortSelection,
        setSearchQuery
    };
};

export default useBuildingDetailsContacts;
