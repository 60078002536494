import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import loginFormSchema from './LoginForm.validators';
import ControllableInput from '../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../shared/ui/buttons/button/Button';
import useLoginForm from './useLoginForm';

type LoginFormProps = {
    onSubmit: SubmitHandler<LoginForm>;
};

type LoginForm = {
    email: string;
    password: string;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
    const { navigateToForgotPassword } = useLoginForm();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<LoginForm>({
        resolver: yupResolver(loginFormSchema),
        defaultValues: {
            email: '',
            password: ''
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    return (
        <div>
            <div className="my-6">
                <ControllableInput
                    name={'email'}
                    control={control}
                    type="text"
                    labelText="Email"
                    errorMessage={errors.email?.message}
                    required={true}
                />
            </div>
            <div className="mt-6">
                <ControllableInput
                    name={'password'}
                    control={control}
                    type="password"
                    labelText="Password"
                    errorMessage={errors.password?.message}
                    required={true}
                />
            </div>
            <div className="mb-14 mt-1 w-full">
                <div className="flex items-center justify-end">
                    <div className="text-sm">
                        <a
                            href="#"
                            className="font-medium text-accent hover:text-primary_hover"
                            onClick={navigateToForgotPassword}
                        >
                            Forgot password?
                        </a>
                    </div>
                </div>
            </div>
            <Button
                text="LOGIN"
                type="button"
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default LoginForm;
