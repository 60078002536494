import { AxiosResponse } from 'axios';
import client from '../../../../../../../core/api/client';
import {
    ApiEndpoints,
    apiEndpoints
} from '../../../../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../../../../shared/hooks/useAxiosClient';
import {
    BuildingWindowPaneType,
    PaneWindowAnnualType,
    PaneWindowMonthType
} from '../state/PaneWindowDefaults';

const usePaneWindowCalculatorApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.CALCULATOR];

    const calculatePaneWindows = (
        buildingId: string,
        paneType: BuildingWindowPaneType | null,
        area: number | null,
        thermalInsulation: number | null,
        costOfHeatingRate: number | null
    ): Promise<AxiosResponse<CalculatePaneWindowsResponse>> => {
        const auth = axiosClient<CalculatePaneWindowsResponse>({
            apiConfig: {
                method: ApiMethod.POST,
                uri: `${endpoint}/${buildingId}/pane-windows`,
                data: { paneType, area, thermalInsulation, costOfHeatingRate }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { calculatePaneWindows };
};

export type CalculatePaneWindowsResponse = {
    monthlyMetrics: Array<PaneWindowMonthType>;
    annualMetrics: PaneWindowAnnualType;
};

export default usePaneWindowCalculatorApi;
