import React from 'react';
import createDataContext from '../../../../../../../shared/contexts/createDataContext';
import WallInsulationReducer from '../state/WallInsulationReducer';
import WallInsulationDefaults from '../state/WallInsulationDefaults';
import WallInsulationCalculatorContext from './WallInsulationCalculatorContext';

export const WallInsulationCalculatorContextProvider = createDataContext(
    WallInsulationReducer,
    WallInsulationDefaults,
    WallInsulationCalculatorContext
);

export default function useWallInsulationCalculatorContext() {
    return React.useContext(WallInsulationCalculatorContext);
}
