import React from 'react';
import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';
import { WallInsulationType } from '../state/WallInsulationDefaults';

interface WallInsulationCalculatorContextType {
    state: WallInsulationType | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const WallInsulationCalculatorContext =
    React.createContext<WallInsulationCalculatorContextType>(
        {} as WallInsulationCalculatorContextType
    );

export default WallInsulationCalculatorContext;
