import { useState } from 'react';
import useBuildingFilters from '../../hooks/useBuildingFilters';
import { buildingTypeMetadata } from '../../../../../shared/metadata/BuildingType.metadata';

const localFilterDefaultValues = {
    priceRange: { minimum: 20, maximum: 45 },
    type: undefined
};

const useDashboardFilterModal = () => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isBuildingTypeDropdownOpen, setBuildingTypeDropdownOpen] =
        useState<boolean>(false);
    const [filters, setFilters] = useState<any>(localFilterDefaultValues);
    const {
        setPriceRange: setPriceRangeGlobal,
        setPropertyType: setPropertyTypeGlobal,
        clearFilters
    } = useBuildingFilters();

    const setModalValue = (value: boolean) => {
        setModalOpen(value);
    };

    const toggleBuildingTypeDropdown = () => {
        setBuildingTypeDropdownOpen(!isBuildingTypeDropdownOpen);
    };

    const setPriceRange = (minimum: number, maximum: number): void => {
        setFilters((filters: any) => ({
            ...filters,
            priceRange: { minimum, maximum }
        }));
    };

    const buildingTypeOptions = Object.entries(buildingTypeMetadata).map(
        ([value, label]) => ({
            value,
            label
        })
    );

    const setPropertyType = (type: string, isChecked: boolean): void => {
        const newSet = new Set<number>(filters.type);
        const parsedType = parseInt(type);

        if (isChecked) {
            newSet.add(parsedType);
        }

        if (!isChecked) {
            newSet.delete(parsedType);
        }

        const output = [...newSet];

        setPropertyTypes(output);
    };

    const setPropertyTypes = (types: number[]): void => {
        setFilters((filters: any) => ({
            ...filters,
            type: types
        }));
    };

    const onApplyFilters = () => {
        const {
            priceRange: { minimum, maximum },
            type
        } = filters;

        setPriceRangeGlobal(minimum, maximum);
        setPropertyTypeGlobal(type);
    };

    const onClearFilters = () => {
        setFilters(localFilterDefaultValues);
        clearFilters();
    };

    return {
        isModalOpen,
        isBuildingTypeDropdownOpen,
        buildingTypeOptions,
        filters,
        setModalValue,
        toggleBuildingTypeDropdown,
        setPriceRange,
        setPropertyType,
        onApplyFilters,
        onClearFilters
    };
};

export default useDashboardFilterModal;
