import * as yup from 'yup';

const schema = yup
    .object({
        heatingPower: yup.object({
            value: yup
                .number()
                .min(1, 'Heating Power must be greater than 0')
                .required('Heating Power is required'),
            isDefaultValue: yup.boolean().required()
        }),
        thermalResistance: yup.object({
            value: yup
                .number()
                .min(1, 'Thermal resistance must be greater than 0')
                .required('Thermal resistance is required'),
            isDefaultValue: yup.boolean().required()
        }),
        durationOfOperation: yup.object({
            value: yup
                .number()
                .min(1, 'Duration of operation must be greater than 0')
                .required('Duration of operation is required'),
            isDefaultValue: yup.boolean().required()
        })
    })
    .required();

export default schema;
