import { Month } from '../../../../../../../shared/enums/Month';

export type UnderfloorHeatingMonthType = {
    month: Month;
    heatOuput: number;
    heatLoss: number;
    energySavings: number;
};

export type UnderfloorHeatingAnnualType = {
    heatOuput: number;
    heatLoss: number;
    energySavings: number;
};

export type UnderfloorHeatingType = {
    monthly: Array<UnderfloorHeatingMonthType>;
    annual: UnderfloorHeatingAnnualType;
};

const UnderfloorHeating: UnderfloorHeatingType | undefined = undefined;

export default UnderfloorHeating;
