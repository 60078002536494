import { AxiosResponse } from 'axios';
import useAxiosClient, {
    ApiMethod
} from '../../../../shared/hooks/useAxiosClient';
import client from '../../../../core/api/client';
import { ApiEndpoints, apiEndpoints } from '../../../../core/api/endpoints';
import { BuildingType } from '../../building-details/state/building/BuildingDefaults';

const useBuildingsApi = () => {
    const axiosClient = useAxiosClient();
    const endpoint = apiEndpoints[ApiEndpoints.GET_BUILDINGS];

    const getBuildings = (
        query: string
    ): Promise<AxiosResponse<BuildingsResponse>> => {
        const auth = axiosClient<BuildingsResponse>({
            apiConfig: {
                method: ApiMethod.GET,
                uri: `${endpoint}?${query}`,
                data: {}
            },
            axiosClientConfig: client
        });

        return auth;
    };

    const getById = (
        id: string
    ): Promise<AxiosResponse<DetailedBuildingReponse>> => {
        const auth = axiosClient<DetailedBuildingReponse>({
            apiConfig: {
                method: ApiMethod.GET,
                uri: `${endpoint}/${id}`,
                data: {}
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { getBuildings, getById };
};

export type BuildingLocationCoordinateResponse = {
    latitude: number;
    longitude: number;
};

export type BuildingLocationResponse = {
    streetName: string;
    streetNumber: string;
    city: string;
    position: BuildingLocationCoordinateResponse;
};

export type BuildingResponse = {
    id: string;
    name: string;
    price: number | null;
    location: BuildingLocationResponse;
};

export type BuildingSchematicResponse = {
    size: number;
    grossSize: number;
    yearBuilt: number;
};

export type DetailedBuildingReponse = {
    id: string;
    name: string;
    type: BuildingType;
    location: BuildingLocationResponse;
    schematic: BuildingSchematicResponse;
};

export type BuildingsResponse = Array<BuildingResponse>;

export default useBuildingsApi;
