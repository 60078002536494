import React, { useState } from 'react';

type TabProp = {
    id: number;
    title: string;
    content: React.ElementType;
};

type TabsProps = {
    data: Array<TabProp>;
};

const DefaultTabs = ({ data: tabData }: TabsProps) => {
    const [activeTab, setActiveTab] = useState(tabData[0].id);

    return (
        <div>
            <div className="mx-4 mt-8 mb-6 bg-white rounded-lg">
                <ul className="flex flex-wrap justify-between text-sm font-medium text-center text-gray-500">
                    {tabData.map((tab) => (
                        <li key={tab.id} className="me-2">
                            <a
                                href="#"
                                onClick={() => setActiveTab(tab.id)}
                                className={`inline-block p-4 ${activeTab === tab.id ? 'text-primary' : 'hover:text-gray-600'}`}
                            >
                                {tab.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div id="default-styled-tab-content rounded-lg">
                {tabData.map((tab) => (
                    <div
                        key={tab.id}
                        className={`p-4 bg-white mx-4 mb-8 rounded-lg ${activeTab === tab.id ? 'block' : 'hidden'}`}
                        role="tabpanel"
                        aria-labelledby={`${tab.title.toLowerCase()}-tab`}
                    >
                        <tab.content />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DefaultTabs;
