import { AxiosResponse } from 'axios';
import useError from '../../../../../../../shared/hooks/useError';
import useLoading from '../../../../../../../shared/hooks/useLoading';
import { useState } from 'react';
import { Month } from '../../../../../../../shared/enums/Month';
import useWallInsulationCalculatorApi, {
    CalculateWallInsulationsResponse
} from './useWallInsulationCalculatorApi';
import useWallInsulationCalculatorContext from '../contexts/useWallInsulationCalculatorContext';
import { WallInsulationForm } from '../wall-insulation-calculator-input/WallInsulationCalculatorInput';
import { WallInsulationActions } from '../state/WallInsulationActions';
import {
    WallInsulationAnnualType,
    WallInsulationMonthType
} from '../state/WallInsulationDefaults';
import useBuilding from '../../../../hooks/useBuilding';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';

const useWallInsulationCalculator = () => {
    const error = useError();
    const loading = useLoading();
    const { building } = useBuilding();

    const wallInsulationCalculatorApi = useWallInsulationCalculatorApi();

    const wallInsulationCalculatorContext =
        useWallInsulationCalculatorContext();

    const [selectedOutputDataOption, setSelectedOutputDataOption] = useState<
        Month | 'Annual'
    >('Annual');

    const handleSelectChange = (event: any) => {
        const selectedValue = event.target.value;

        if (selectedValue === 'Annual') {
            setSelectedOutputDataOption(selectedValue);
            return;
        }

        setSelectedOutputDataOption(parseInt(selectedValue));
    };

    const addThermalResistanceValue = (
        append: UseFieldArrayAppend<WallInsulationForm, never>
    ) => {
        append(1);
    };

    const removeThermalResistanceValueAtIndex = (
        remove: UseFieldArrayRemove,
        index: number
    ) => {
        remove(index);
    };

    const onSubmit = (values: WallInsulationForm) => {
        if (building === undefined) {
            return;
        }

        let thermalResistanceAssembly: number[] | null =
            values.thermalResistanceAssembly.value;
        let costOfHeatingRate: number | null = values.costOfHeatingRate.value;

        if (values.thermalResistanceAssembly.isDefaultValue) {
            thermalResistanceAssembly = null;
        }

        if (values.costOfHeatingRate.isDefaultValue) {
            costOfHeatingRate = null;
        }

        loading.load();
        wallInsulationCalculatorApi
            .calculateWallInsulations(
                building.id,
                thermalResistanceAssembly,
                costOfHeatingRate
            )
            .then(
                (
                    response: AxiosResponse<
                        CalculateWallInsulationsResponse,
                        any
                    >
                ) => {
                    const { data } = response;
                    const { monthlyMetrics, annualMetrics } = data;

                    wallInsulationCalculatorContext.dispatch({
                        type: WallInsulationActions.SET_SOLAR_PANEL,
                        payload: {
                            monthly: monthlyMetrics,
                            annual: annualMetrics
                        }
                    });
                }
            )
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const filterDataByOption = (
        option: Month | 'Annual'
    ): WallInsulationAnnualType | WallInsulationMonthType | undefined => {
        if (option === 'Annual') {
            return wallInsulationCalculatorContext.state?.annual;
        }

        return wallInsulationCalculatorContext.state?.monthly.find(
            (monthData) => monthData.month === option
        );
    };

    return {
        wallInsulationCalculatorData: wallInsulationCalculatorContext.state,
        selectedOutputDataOption,
        handleSelectChange,
        addThermalResistanceValue,
        removeThermalResistanceValueAtIndex,
        onSubmit,
        filterDataByOption
    };
};

export default useWallInsulationCalculator;
