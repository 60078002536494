import { useNavigate } from 'react-router-dom';
import { Routes, RoutingKeys } from '../../../../shared/routes/router.keys';

const useWaitlist = () => {
    const navigate = useNavigate();

    const navigateToLogin = (e: any) => {
        e.preventDefault();
        navigate(RoutingKeys[Routes.LOGIN]);
    };

    return { navigateToLogin };
};

export default useWaitlist;
