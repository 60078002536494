import React, { useState } from 'react';
import useBuilding from '../../hooks/useBuilding';

const BuildingDetailsPanel = () => {
    const { building } = useBuilding();

    return (
        <div className="flex flex-col p-4 mb-4">
            <p className="text-primary mb-4">Building info</p>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Size</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    {building?.schematic?.size}
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Gross Size:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    {building?.schematic?.grossSize}
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Year Built:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    {building?.schematic?.yearBuilt}
                </span>
            </div>
            {/* <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Use:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Residential area
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Energy efficiency class:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Energy requirements:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Total annual energy demand:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Type:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Apartment building
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Old:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    1966
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Plot area:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    909 m^2
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Dimensions:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    100 x 20 x 15
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Built up area:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    115 m^2
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">GRZ:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    0.39
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">GRZ2:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    0.00
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Floors:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Full floors:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">GFA (total):</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Gross floor area of the full floors:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Usable area (factor 0.72):
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Roof surfaces:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Facade surfaces:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">
                    Enveloping surfaces:
                </span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Total volume:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">BMZ:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    0.95
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Urban:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    no
                </span>
            </div>
            <div className="flex flex-row justify-between my-1 items-center">
                <span className="w-1/2 text-sm text-accent">Development:</span>
                <span className="w-1/2 text-sm border border-gray-200 px-2 py-1">
                    Sensitive
                </span>
            </div> */}
        </div>
    );
};

export default BuildingDetailsPanel;
