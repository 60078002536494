import React from 'react';
import createDataContext from '../createDataContext';
import SessionDataReducer from '../../../state/session-data/SessionDataReducer';
import SessionDataDefaults from '../../../state/session-data/SessionDataDefaults';
import SessionDataContext from './SessionDataContext';

export const SessionDataContextProvider = createDataContext(
    SessionDataReducer,
    SessionDataDefaults,
    SessionDataContext
);

export default function useSessionDataContext() {
    return React.useContext(SessionDataContext);
}
