enum DashbordItemsHeaderSortOption {
    Default = 0,
    Asc,
    Desc,
    HighestPrice,
    LowestPrice
}

const dashboardItemsHeaderMetadata: Record<
    DashbordItemsHeaderSortOption,
    string
> = {
    [DashbordItemsHeaderSortOption.Default]: 'Sort by default',
    [DashbordItemsHeaderSortOption.Asc]: 'Name Ascending',
    [DashbordItemsHeaderSortOption.Desc]: 'Name Descending',
    [DashbordItemsHeaderSortOption.HighestPrice]: 'Price (highest first)',
    [DashbordItemsHeaderSortOption.LowestPrice]: 'Price (lowest first)'
};

export default dashboardItemsHeaderMetadata;
