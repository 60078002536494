import React from 'react';

type AccordionProps = {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    title: string;
    children: React.ReactElement;
};

const Accordion = ({ isOpen, setOpen, title, children }: AccordionProps) => {
    return (
        <div id="accordion-collapse" data-accordion="collapse">
            <h2 id="accordion-collapse-heading-1">
                <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b border-gray-200 focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 gap-3"
                    onClick={() => setOpen(!isOpen)}
                    aria-expanded={isOpen}
                    aria-controls="accordion-collapse-body-1"
                >
                    <span>{title}</span>
                    <svg
                        data-accordion-icon
                        className={`w-3 h-3 shrink-0 transition-transform duration-200 ${!isOpen ? 'rotate-180' : ''}`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5 5 1 1 5"
                        />
                    </svg>
                </button>
            </h2>
            <div
                id="accordion-collapse-body-1"
                className={`${isOpen ? '' : 'hidden'}`}
                aria-labelledby="accordion-collapse-heading-1"
            >
                <div className="p-5 border border-b border-gray-200">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
