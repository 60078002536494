import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import useBuildingDetailsHeader from './hooks/useBuildingDetailsHeader';

const BuildingDetailsHeader = () => {
    const { navigateToDashboard } = useBuildingDetailsHeader();

    return (
        <div
            className="flex flex-row h-full px-4 items-center cursor-pointer"
            onClick={navigateToDashboard}
        >
            <BsArrowLeft className="bg-gray-200 text-black text-2xl rounded-full p-1" />
            <span className="pl-4 text-lg">Back</span>
        </div>
    );
};

export default BuildingDetailsHeader;
