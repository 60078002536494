import React from 'react';
import { MdLocationOn } from 'react-icons/md';
import useBuilding from '../../hooks/useBuilding';

const BuildingDetailsCard = () => {
    const { building } = useBuilding();

    return (
        <div className="p-4 border-b border-gray-300">
            <h1 className="text-xl font-semibold pb-1">{building?.name}</h1>
            <div className="flex flex-row mb-4 items-center">
                <MdLocationOn className="bg-gray-400 text-white text-lg rounded-full p-0.5 mr-1" />
                <span className="text-gray-400 text-sm">
                    {`${building?.location?.streetName} ${building?.location?.streetNumber} ${building?.location?.zipCode} ${building?.location?.country}`}
                </span>
            </div>
            <img
                className="rounded-lg h-60 w-full"
                alt="Building photo"
                src="https://www.sherlock-holmes.co.uk/wp-content/uploads/2020/02/museum-crop-scaled-e1680133668502.jpg"
            />
            <div className="mt-4 align-baseline">
                <span>Price:</span>
                <span className="ml-4 font-semibold text-lg">$N/A</span>
            </div>
        </div>
    );
};

export default BuildingDetailsCard;
