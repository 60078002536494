import axios, { InternalAxiosRequestConfig } from 'axios';

import { apiEndpoints, ApiEndpoints } from './endpoints';
import AuthService from '../auth/auth.service';

const api = axios.create({
    baseURL: apiEndpoints[ApiEndpoints.BASE],
    timeout: 8000,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(
    (
        config: InternalAxiosRequestConfig<any>
    ):
        | InternalAxiosRequestConfig<any>
        | Promise<InternalAxiosRequestConfig<any>> => {
        const token = AuthService.getToken();

        if (token) {
            if (!config) {
                throw new Error('Request config is undefined');
            }

            if (!config.headers) {
                throw new Error('Request config headers are undefined');
            }

            config.headers.Authorization = 'Bearer ' + token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
