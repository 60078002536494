import React from 'react';
import { MapOptionsType } from '../state/map-options/MapOptionsDefaults';
import { IReducerAction } from '../../../../../shared/models/IReducerAction';

interface MapOptionsContextType {
    state: MapOptionsType;
    dispatch: React.Dispatch<IReducerAction>;
}

const MapOptionsContext = React.createContext<MapOptionsContextType>(
    {} as MapOptionsContextType
);

export default MapOptionsContext;
