import { Month } from '../../../../../../../shared/enums/Month';

export type SolarPanelMonthType = {
    month: Month;
    powerOutput: number;
    totalPowerOutput: number;
    energyProduction: number;
    costReduction: number;
};

export type SolarPanelAnnualType = {
    powerOutput: number;
    totalPowerOutput: number;
    energyProduction: number;
    costReduction: number;
};

export type SolarPanelType = {
    monthly: Array<SolarPanelMonthType>;
    annual: SolarPanelAnnualType;
};

const SolarPanel: SolarPanelType | undefined = undefined;

export default SolarPanel;
