import {
    Cesium3DTileset,
    CesiumComponentRef,
    Entity,
    ImageryLayer,
    Viewer
} from 'resium';
import {
    Ion,
    createWorldTerrainAsync,
    Viewer as CesiumViewer,
    Color,
    Cartesian3,
    HeightReference
} from 'cesium';
import useDashboardMap from './hooks/useDashboardMap';
import './DashboardMap.css';
import useImageryProvider from './hooks/useImageryProvider';
import useTilesetResource from './hooks/useTilesetResource';
import MapActions from './components/map-actions/MapActions';
import {
    MapPerspective,
    MapTerrainType
} from './state/map-options/MapOptionsDefaults';
import { useEffect, useRef, useState } from 'react';
import useDestinationLookupContext from '../contexts/destination-lookup/useDestinationLookupContext';
import useGeometry from '../hooks/useGeometry';
import useLocationFrame from '../hooks/useLocationFrame';
import usePins from './hooks/usePins';
import useBuildings from '../hooks/useBuildings';
import MapBuildingDetails from './components/map-building-detail/MapBuildingDetails';
import useBuildingFilters from '../hooks/useBuildingFilters';

Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_ION_TOKEN!;

export const MAP_MAXIMUM_HEIGHT_FOR_FETCH = 3.5;

const DashboardMap = () => {
    const [selectedPin, setSelectedPin] = useState<any>(null);
    const viewerRef = useRef<CesiumComponentRef<CesiumViewer> | null>(null);

    const { buildings } = useBuildings();
    const { filters } = useBuildingFilters();
    const { pins, filterAndDisplayPins, onCameraMove } = usePins();
    const { mapOptions, userLocation, moveCameraToLocation } =
        useDashboardMap();
    const dashboardLookup = useDestinationLookupContext();
    const imageryProvider = useImageryProvider();
    const tilesetResource = useTilesetResource();

    useEffect(() => {
        if (dashboardLookup.state === undefined) {
            return;
        }

        if (viewerRef === null) {
            return;
        }

        const { latitude, longitude } = dashboardLookup.state;

        moveCameraToLocation(viewerRef, latitude, longitude);
    }, [dashboardLookup.state]);

    useEffect(() => {
        if (!viewerRef) {
            return;
        }

        if (!viewerRef.current!.cesiumElement) {
            return;
        }

        const scene = viewerRef.current!.cesiumElement!.scene;
        const cam = scene.camera;

        cam.moveEnd.addEventListener(() => onCameraMove(cam, scene));
    }, [viewerRef, viewerRef.current?.cesiumElement?.scene, buildings]);

    useEffect(() => {
        filterAndDisplayPins(buildings, 10);
    }, [filters]);

    const handlePinClick = (pin: any) => {
        const pinBuilding = buildings.find(
            (building) => building.id === pin.buildingId
        );

        setSelectedPin(pinBuilding);

        if (!pinBuilding) {
            return;
        }

        moveCameraToLocation(
            viewerRef,
            pinBuilding.location.position.latitude,
            pinBuilding.location.position.longitude,
            0.5
        );
    };

    const closeModal = () => {
        setSelectedPin(null);
    };

    return (
        <div className="relative w-full h-full">
            <Viewer
                ref={viewerRef}
                className="w-full h-full"
                terrainProvider={createWorldTerrainAsync()}
                animation={false}
                timeline={false}
                fullscreenButton={false}
                baseLayerPicker={false}
                infoBox={false}
                homeButton={false}
                navigationHelpButton={false}
                projectionPicker={false}
                sceneModePicker={false}
                geocoder={false}
                selectionIndicator={false}
            >
                {userLocation !== undefined && (
                    <Entity
                        position={Cartesian3.fromDegrees(
                            userLocation.longitude,
                            userLocation.latitude
                        )}
                        point={{
                            pixelSize: 15,
                            color: Color.BLUE,
                            heightReference: HeightReference.CLAMP_TO_GROUND
                        }}
                    />
                )}
                {mapOptions.perspective === MapPerspective.THREE_DIMENSION && (
                    <Cesium3DTileset
                        url={tilesetResource.getResource(mapOptions.renderType)}
                    />
                )}
                <>
                    {mapOptions.terrainType === MapTerrainType.SATELLITE && (
                        <ImageryLayer
                            imageryProvider={imageryProvider.satelliteProvider}
                        />
                    )}
                    {mapOptions.terrainType === MapTerrainType.STREET && (
                        <ImageryLayer
                            imageryProvider={imageryProvider.streetProvider}
                        />
                    )}
                </>
                {pins.length > 0 &&
                    pins.map((pin, index) => (
                        <Entity
                            key={index}
                            name={pin.name}
                            position={pin.position}
                            billboard={pin.billboard}
                            onClick={() => handlePinClick(pin)}
                        ></Entity>
                    ))}
            </Viewer>
            {selectedPin && (
                <MapBuildingDetails pin={selectedPin} onClose={closeModal} />
            )}
            <MapActions mapViewerRef={viewerRef} />
        </div>
    );
};

export default DashboardMap;
