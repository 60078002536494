import useLoadingContext from '../../../../shared/contexts/loading/useLoadingContext';
import { LoadingActions } from '../../../../state/loading/LoadingActions';
import { BuildingActions } from '../../building-details/state/building/BuildingActions';
import useBuildingsContext from '../contexts/buildings/useBuildingsContext';
import { BuildingFiltersPriceRange } from '../state/building-filters/BuildingFiltersDefaults';
import { BuildingsActions } from '../state/buildings/BuildingsActions';
import { LocationFrameType } from '../state/location-frame/LocationFrameDefaults';
import useBuildingsApi from './useBuildingsApi';

const useBuildings = () => {
    const buildings = useBuildingsContext();
    const loading = useLoadingContext();
    const buildingsApi = useBuildingsApi();

    const fetchBuildings = (
        lookup: LocationFrameType | undefined,
        priceRange: BuildingFiltersPriceRange | undefined,
        type: number[] | undefined
    ): void => {
        let queries = [];
        let query = ``;

        if (lookup !== undefined) {
            const ne = lookup.topLeft;
            const nw = lookup.topRight;
            const se = lookup.bottomLeft;
            const sw = lookup.bottomRight;

            queries.push(
                `NorthEast.Latitude=${ne.latitude}&NorthEast.Longitude=${ne.longitude}&NorthWest.Latitude=${nw.latitude}&NorthWest.Longitude=${nw.longitude}&SouthEast.Latitude=${se.latitude}&SouthEast.Longitude=${se.longitude}&SouthWest.Latitude=${sw.latitude}&SouthWest.Longitude=${sw.longitude}`
            );
        }

        if (priceRange !== undefined) {
            queries.push(
                `MinPrice=${priceRange.minimum}&MaxPrice=${priceRange.maximum}`
            );
        }

        if (type !== undefined) {
            type.map((type, index) => {
                queries.push(`Type[${index}]=${type}`);
            });
        }

        query = queries.join('&');

        loading.dispatch({
            type: LoadingActions.LOADING
        });

        buildingsApi
            .getBuildings(query)
            .then((buildingsResponse) => {
                const buildingsData = buildingsResponse.data;

                const parsedBuildingsData = buildingsData.map(
                    (buildingData) => {
                        return {
                            id: buildingData.id,
                            name: buildingData.name,
                            imageUrl: '',
                            location: buildingData.location,
                            price: buildingData.price,
                            isFavorite: false
                        };
                    }
                );

                buildings.dispatch({
                    type: BuildingsActions.SET_BUILDINGS,
                    payload: parsedBuildingsData
                });
            })
            .catch((e) => {
                console.error('ERROR FETCHING BUILDINGS', e);
            })
            .finally(() => {
                loading.dispatch({
                    type: LoadingActions.LOADED
                });
            });
    };

    return { buildings: buildings.state, fetchBuildings };
};

export default useBuildings;
