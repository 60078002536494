import React from 'react';
import createDataContext from '../../../../shared/contexts/createDataContext';
import WaitlistUsersReducer from '../state/WaitlistUsersReducer';
import WaitlistUsersDefaults from '../state/WaitlistUsersDefaults';
import WaitlistUsersContext from './WaitlistUsersContext';

export const WaitlistUsersContextProvider = createDataContext(
    WaitlistUsersReducer,
    WaitlistUsersDefaults,
    WaitlistUsersContext
);

export default function useWaitlistUsersContext() {
    return React.useContext(WaitlistUsersContext);
}
