import { IonImageryProvider } from 'cesium';
import { MapTerrainType } from '../state/map-options/MapOptionsDefaults';

const useImageryProvider = () => {
    const streetProvider = IonImageryProvider.fromAssetId(
        parseInt(process.env.REACT_APP_CESIUM_SATELLITE_IMAGERY_ID!)
    );
    const satelliteProvider = IonImageryProvider.fromAssetId(
        parseInt(process.env.REACT_APP_CESIUM_STREET_IMAGERY_ID!)
    );

    const getProvider = (terrainType: MapTerrainType) => {
        switch (terrainType) {
            case MapTerrainType.STREET:
                return IonImageryProvider.fromAssetId(
                    parseInt(process.env.REACT_APP_CESIUM_SATELLITE_IMAGERY_ID!)
                );
            case MapTerrainType.SATELLITE:
                return IonImageryProvider.fromAssetId(
                    parseInt(process.env.REACT_APP_CESIUM_STREET_IMAGERY_ID!)
                );
        }
    };

    return { streetProvider, satelliteProvider, getProvider };
};

export default useImageryProvider;
