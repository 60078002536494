import { IReducerAction } from '../../../../../shared/models/IReducerAction';
import ReducerType from '../../../../../shared/types/ReducerType';
import { BuildingActions } from './BuildingActions';
import { DetailedBuildingType } from './BuildingDefaults';

const BuildingReducer: ReducerType<
    DetailedBuildingType | undefined,
    IReducerAction
> = (state: DetailedBuildingType | undefined, action: IReducerAction) => {
    switch (action.type) {
        case BuildingActions.SET_BUILDING:
            return action.payload;
        case BuildingActions.CLEAR_BUILDING:
            return undefined;
        default:
            return state;
    }
};

export default BuildingReducer;
