import React, { useState } from 'react';
import useBuildingDetailsPhotos from './hooks/useBuildingDetailsPhotos';

const BuildingDetailsPhotos = () => {
    const {
        showAllPhotos,
        photos,
        handleSeeAllClick,
        handleShowLessClick,
        generatePhotoLayout
    } = useBuildingDetailsPhotos();

    return (
        <div>
            <div className="p-4 border-t border-t-gray-300">
                <h2 className="mb-4 text-xl font-semibold">Photos:</h2>
                {generatePhotoLayout(
                    showAllPhotos ? photos : photos.slice(0, 3)
                ).map((row, rowIndex) => (
                    <div key={rowIndex} className="flex gap-2 mb-2">
                        {row.map((photo: string, photoIndex: number) => (
                            <div
                                key={photoIndex}
                                className={`flex-1 ${rowIndex === 1 && photoIndex === 1 && !showAllPhotos ? 'relative cursor-pointer' : ''}`}
                                onClick={
                                    rowIndex === 1 &&
                                    photoIndex === 1 &&
                                    !showAllPhotos
                                        ? handleSeeAllClick
                                        : undefined
                                }
                            >
                                <img
                                    src={photo}
                                    alt={`Photo ${rowIndex * 2 + photoIndex + 1}`}
                                    className="w-full h-full object-cover rounded-md shadow-md"
                                />
                                {rowIndex === 1 &&
                                    photoIndex === 1 &&
                                    !showAllPhotos && (
                                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md">
                                            <span className="text-white text-lg font-bold">
                                                See all {photos.length} photos
                                            </span>
                                        </div>
                                    )}
                            </div>
                        ))}
                    </div>
                ))}
                {showAllPhotos && (
                    <div className="flex justify-end mt-4">
                        <button
                            className="text-accent px-4 underline"
                            onClick={handleShowLessClick}
                        >
                            Show less
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BuildingDetailsPhotos;
