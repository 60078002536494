import useBuildingFiltersContext from '../contexts/building-filters/useBuildingFiltersContext';
import { BuildingFiltersActions } from '../state/building-filters/BuildingFiltersActions';
import { BuildingType } from '../state/buildings/BuildingsDefaults';

const useBuildingFilters = () => {
    const buildingFilters = useBuildingFiltersContext();

    const clearFilters = (): void => {
        buildingFilters.dispatch({
            type: BuildingFiltersActions.CLEAR_BUILDING_FILTERS
        });
    };

    const setPriceRange = (minimum: number, maximum: number): void => {
        buildingFilters.dispatch({
            type: BuildingFiltersActions.UPDATE_BUILDING_FILTERS,
            payload: { priceRange: { minimum, maximum } }
        });
    };

    const setPropertyType = (type: number[]): void => {
        buildingFilters.dispatch({
            type: BuildingFiltersActions.UPDATE_BUILDING_FILTERS,
            payload: { type }
        });
    };

    const filterBuildings = (building: BuildingType) => {
        if (buildingFilters.state === undefined) {
            return true;
        }

        return (
            building.price.value >=
                buildingFilters.state?.priceRange.minimum! * 1000000 &&
            building.price.value <=
                buildingFilters.state?.priceRange.maximum! * 1000000
        );
    };

    return {
        filters: buildingFilters.state,
        clearFilters,
        setPriceRange,
        setPropertyType,
        filterBuildings
    };
};

export default useBuildingFilters;
