import React from 'react';
import createDataContext from '../../../../../shared/contexts/createDataContext';
import DestinationLookupReducer from '../../state/destination-lookup/DestinationLookupReducer';
import DestinationLookupDefaults from '../../state/destination-lookup/DestinationLookupDefaults';
import DestinationLookupContext from './DestinationLookupContext';

export const DestinationLookupContextProvider = createDataContext(
    DestinationLookupReducer,
    DestinationLookupDefaults,
    DestinationLookupContext
);

export default function useDestinationLookupContext() {
    return React.useContext(DestinationLookupContext);
}
