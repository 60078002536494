import React from 'react';
import createDataContext from '../../../../../shared/contexts/createDataContext';
import LocationFrameReducer from '../../state/location-frame/LocationFrameReducer';
import LocationFrameDefaults from '../../state/location-frame/LocationFrameDefaults';
import LocationFrameContext from './LocationFrameContext';

export const LocationFrameContextProvider = createDataContext(
    LocationFrameReducer,
    LocationFrameDefaults,
    LocationFrameContext
);

export default function useLocationFrameContext() {
    return React.useContext(LocationFrameContext);
}
