import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerFormSchema from './RegisterForm.validators';
import ControllableInput from '../../../../../shared/ui/inputs/controllable-input/ControllableInput';
import Button from '../../../../../shared/ui/buttons/button/Button';

type RegisterFormProps = {
    onSubmit: SubmitHandler<RegisterForm>;
};

type RegisterForm = {
    name: string;
    email: string;
    password: string;
};

const RegisterForm = ({ onSubmit }: RegisterFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<RegisterForm>({
        resolver: yupResolver(registerFormSchema),
        defaultValues: {
            name: '',
            email: '',
            password: ''
        },
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    return (
        <div>
            <div className="my-6">
                <ControllableInput
                    name={'name'}
                    control={control}
                    type="text"
                    labelText="Name"
                    errorMessage={errors.name?.message}
                    required={true}
                />
            </div>
            <div className="my-6">
                <ControllableInput
                    name={'email'}
                    control={control}
                    type="text"
                    labelText="Email"
                    errorMessage={errors.email?.message}
                    required={true}
                />
            </div>
            <div className="my-6 mb-14">
                <ControllableInput
                    name={'password'}
                    control={control}
                    type="password"
                    labelText="Password"
                    errorMessage={errors.password?.message}
                    required={true}
                />
            </div>
            <Button
                text="REGISTER"
                type="button"
                onClick={handleSubmit(onSubmit)}
            />
        </div>
    );
};

export default RegisterForm;
