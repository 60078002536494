export type DetailedBuildingType = {
    id: string;
    name: string;
    type: BuildingType;
    location: any;
    schematic: any;
};

export enum BuildingType {
    NONE = 0,
    SINGLE_FAMILY_RESIDENCE,
    TOWN_HOUSE,
    CONDOMINIUM,
    OFFICE_BUILDING,
    MISSCELLANEOUS,
    RETAIL,
    AUTOREPAIRGARAGE,
    ZONE,
    FINANCIAL_BUILDING
}

const BuildingDefaults: DetailedBuildingType | undefined = undefined;

export default BuildingDefaults;
