import { AxiosResponse } from 'axios';
import useError from '../../../../../../../shared/hooks/useError';
import useLoading from '../../../../../../../shared/hooks/useLoading';
import useUnderfloorHeatingCalculatorApi, {
    CalculateUnderfloorHeatingsResponse
} from './useUnderfloorHeatingCalculatorApi';
import { useState } from 'react';
import { UnderfloorHeatingForm } from '../underfloor-heating-calculator-input/UnderfloorHeatingCalculatorInput';
import { Month } from '../../../../../../../shared/enums/Month';
import useUnderfloorHeatingCalculatorContext from '../contexts/useUnderfloorHeatingCalculatorContext';
import { UnderfloorHeatingActions } from '../state/UnderfloorHeatingActions';
import {
    UnderfloorHeatingAnnualType,
    UnderfloorHeatingMonthType
} from '../state/UnderfloorHeatingDefaults';
import useBuilding from '../../../../hooks/useBuilding';

const useUnderfloorHeatingCalculator = () => {
    const { building } = useBuilding();

    const error = useError();
    const loading = useLoading();

    const underfloorHeatingCalculatorApi = useUnderfloorHeatingCalculatorApi();

    const underfloorHeatingCalculatorContext =
        useUnderfloorHeatingCalculatorContext();

    const [selectedOutputDataOption, setSelectedOutputDataOption] = useState<
        Month | 'Annual'
    >('Annual');

    const handleSelectChange = (event: any) => {
        const selectedValue = event.target.value;

        if (selectedValue === 'Annual') {
            setSelectedOutputDataOption(selectedValue);
            return;
        }

        setSelectedOutputDataOption(parseInt(selectedValue));
    };

    const onSubmit = (values: UnderfloorHeatingForm) => {
        if (building === undefined) {
            return;
        }

        let heatingPower: number | null = values.heatingPower.value;
        let thermalResistance: number | null = values.thermalResistance.value;
        let durationOfOperation: number | null =
            values.durationOfOperation.value;

        if (values.heatingPower.isDefaultValue) {
            heatingPower = null;
        }

        if (values.thermalResistance.isDefaultValue) {
            thermalResistance = null;
        }

        if (values.durationOfOperation.isDefaultValue) {
            durationOfOperation = null;
        }

        loading.load();
        underfloorHeatingCalculatorApi
            .calculateUnderfloorHeating(
                building.id,
                heatingPower,
                thermalResistance,
                durationOfOperation
            )
            .then(
                (
                    response: AxiosResponse<
                        CalculateUnderfloorHeatingsResponse,
                        any
                    >
                ) => {
                    const { data } = response;
                    const { monthlyMetrics, annualMetrics } = data;

                    underfloorHeatingCalculatorContext.dispatch({
                        type: UnderfloorHeatingActions.SET_SOLAR_PANEL,
                        payload: {
                            monthly: monthlyMetrics,
                            annual: annualMetrics
                        }
                    });
                }
            )
            .catch((e) => {
                error.parseAndSetErrorMessage(e);
            })
            .finally(() => {
                loading.loaded();
            });
    };

    const filterDataByOption = (
        option: Month | 'Annual'
    ): UnderfloorHeatingAnnualType | UnderfloorHeatingMonthType | undefined => {
        if (option === 'Annual') {
            return underfloorHeatingCalculatorContext.state?.annual;
        }

        return underfloorHeatingCalculatorContext.state?.monthly.find(
            (monthData) => monthData.month === option
        );
    };

    return {
        underfloorHeatingCalculatorData:
            underfloorHeatingCalculatorContext.state,
        selectedOutputDataOption,
        handleSelectChange,
        onSubmit,
        filterDataByOption
    };
};

export default useUnderfloorHeatingCalculator;
