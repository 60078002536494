import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useError from '../../../hooks/useError';
import Toast from '../../toast/Toast';
import useSuccess from '../../../hooks/useSuccess';
import useLoading from '../../../hooks/useLoading';
import Loader from '../../loader/Loader';

const AlertWrapperLayout = () => {
    const { error, clearErrorMessage } = useError();
    const { success, clearSuccessMessage } = useSuccess();
    const { loading } = useLoading();

    useEffect(() => {
        if (error.isError) {
            const errorTimeout = setTimeout(() => {
                clearErrorMessage();
            }, 5000);

            return () => clearTimeout(errorTimeout);
        }

        if (success.isSuccess) {
            const successTimeout = setTimeout(() => {
                clearSuccessMessage();
            }, 5000);

            return () => clearTimeout(successTimeout);
        }
    }, [error, success]);

    return (
        <div>
            {loading.isLoading && <Loader />}
            {error.isError && (
                <Toast
                    text={error.message}
                    type="error"
                    onClose={clearErrorMessage}
                />
            )}
            {success.isSuccess && (
                <Toast
                    text={success.message}
                    type="success"
                    onClose={clearSuccessMessage}
                />
            )}
            <Outlet />
        </div>
    );
};

export default AlertWrapperLayout;
