import React, { useState } from 'react';

const useBuildingDetailsOverview = () => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isSolarPanelsOpen, setIsSolarPanelsOpen] = useState(false);
    const [isPaneWindowsOpen, setIsPaneWindowsOpen] = useState(false);
    const [isUnderfloorHeatingOpen, setIsUnderfloorHeatingOpen] =
        useState(false);
    const [isWallInsulationsOpen, setIsWallInsulationsOpen] = useState(false);

    const toggleAccordionSolarPanels = () => {
        setIsSolarPanelsOpen(!isSolarPanelsOpen);
    };

    const toggleAccordionOfPaneWindows = () => {
        setIsPaneWindowsOpen(!isPaneWindowsOpen);
    };

    const toggleUnderfloorHeatingAccordion = () => {
        setIsUnderfloorHeatingOpen(!isUnderfloorHeatingOpen);
    };

    const toggleAccordionOfWallInsulations = () => {
        setIsWallInsulationsOpen(!isWallInsulationsOpen);
    };

    const generateDayWiseTimeSeries = (
        baseTime: any,
        count: any,
        range: any
    ) => {
        let series = [];
        let x = baseTime;
        for (let i = 0; i < count; i++) {
            let y =
                Math.floor(Math.random() * (range.max - range.min + 1)) +
                range.min;
            series.push([x, y]);
            x += 86400000;
        }
        return series;
    };

    const chartsData: any = {
        series: [
            {
                name: 'Price',
                data: generateDayWiseTimeSeries(
                    new Date('11 Feb 2017 GMT').getTime(),
                    20,
                    {
                        min: 10,
                        max: 60
                    }
                )
            },
            {
                name: 'Crime Rate',
                data: generateDayWiseTimeSeries(
                    new Date('11 Feb 2017 GMT').getTime(),
                    20,
                    {
                        min: 10,
                        max: 20
                    }
                )
            },
            {
                name: 'Air Index',
                data: generateDayWiseTimeSeries(
                    new Date('11 Feb 2017 GMT').getTime(),
                    20,
                    {
                        min: 10,
                        max: 15
                    }
                )
            }
        ],
        options: {
            chart: {
                type: 'area',
                height: 350,
                stacked: true,
                events: {
                    selection: function (chart: any, e: any) {
                        console.log(new Date(e.xaxis.min));
                    }
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#008FFB', '#00E396', '#CED4DC'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'monotoneCubic'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 0.6,
                    opacityTo: 0.8
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            xaxis: {
                type: 'datetime'
            }
        }
    };

    const items = [
        {
            id: 'amenities',
            label: 'Amenties',
            color: 'bg-sky-400',
            percentage: 20
        },
        {
            id: 'security',
            label: 'Security',
            color: 'bg-blue-500',
            percentage: 30
        },
        {
            id: 'greenSpaces',
            label: 'Green Spaces',
            color: 'bg-green-700',
            percentage: 10
        },
        {
            id: 'shopping',
            label: 'Shopping',
            color: 'bg-blue-900',
            percentage: 10
        },
        { id: 'park', label: 'Park', color: 'bg-blue-700', percentage: 10 },
        {
            id: 'solarPanels',
            label: 'Solar Panels',
            color: 'bg-yellow-500',
            percentage: 15
        }
    ];

    const handleButtonClick = (itemId: any) => {
        if (itemId === 'solarPanels') {
            setIsModalOpen(true);
        } else {
            setSelectedItems((prevSelectedItems) =>
                prevSelectedItems.includes(itemId)
                    ? prevSelectedItems.filter((id) => id !== itemId)
                    : [...prevSelectedItems, itemId]
            );
        }
    };

    const calculateTotalPercentage = () => {
        return selectedItems.reduce((total, id) => {
            const item = items.find((i) => i.id === id);
            return total + (item ? item.percentage : 0);
        }, 0);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return {
        isSolarPanelsOpen,
        isPaneWindowsOpen,
        isUnderfloorHeatingOpen,
        isWallInsulationsOpen,
        chartsData,
        items,
        selectedItems,
        isModalOpen,
        toggleAccordionSolarPanels,
        toggleAccordionOfPaneWindows,
        toggleUnderfloorHeatingAccordion,
        toggleAccordionOfWallInsulations,
        calculateTotalPercentage,
        handleButtonClick,
        closeModal
    };
};

export default useBuildingDetailsOverview;
