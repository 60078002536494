enum Currency {
    EURO = 0
}

type Price = {
    value: number;
    currency: Currency;
};

type Position = {
    latitude: number;
    longitude: number;
};

type DetailedLocation = {
    streetName: string;
    streetNumber: number;
    zipCode: number;
    city: string;
    position: Position;
};

export type BuildingType = {
    id: string;
    name: string;
    imageUrl: string;
    location: DetailedLocation;
    price: Price;
    isFavorite: boolean;
};

const Buildings: BuildingType[] = [];

export default Buildings;
