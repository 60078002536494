import { useState } from 'react';
import useToggle from '../../../../../../../shared/hooks/useToggle';

const useBuildingCompare = () => {
    const { isActive: isModalActive, setToggle } = useToggle();

    const setModalValue = (value: boolean) => {
        setToggle(value);
    };

    const getChartData = (data: Array<{ key: string; value: number[] }>) => {
        return {
            series: data.map((value) => ({
                name: value.key,
                data: value.value
            })),
            options: { ...getChartOptions() }
        };
    };

    const getChartOptions = (): any => {
        return {
            chart: {
                type: 'bar',
                height: 400,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '85%',
                    endingShape: 'rounded',
                    distributed: true,
                    dataLabels: {
                        position: 'top'
                    },
                    style: {
                        paddingRight: 30
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -10,
                style: {
                    colors: ['gray'],
                    fontSize: '7px'
                },
                formatter: function (val: any, opt: any) {
                    return `$` + val * 1000;
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            xaxis: {
                categories: [
                    'Single family',
                    'Townhouse',
                    'Condo',
                    'Semi-Detached',
                    'All property types'
                ]
            },
            yaxis: {
                tickAmount: 7,
                labels: {
                    formatter: function (value: number) {
                        return value !== 0
                            ? `$${value.toFixed(1)}M`
                            : `${value}M`;
                    }
                },
                min: 0,
                max: 1.4
            },
            fill: {
                opacity: 1,
                colors: ['#ff2424', '#a19c9a', '#289993', '#dead0d', '#094d85']
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                markers: {
                    shape: undefined,
                    fillColors: ['lightgray', 'lightgray', 'lightgray']
                }
            },
            tooltip: {
                enabled: false
            },
            title: {
                text: 'March Average Sales Price - By Property Type',
                align: 'left',
                marginTop: 30,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#263238'
                }
            }
        };
    };

    return { isModalOpen: isModalActive, setModalValue, getChartData };
};

export default useBuildingCompare;
