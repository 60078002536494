import { IoOptionsOutline } from 'react-icons/io5';
import { Slider } from 'antd';
import './DashboardFilterModal.css';
import useDashboardFilterModal from '../../hooks/useDashboardFilterModal';

type DashboardFilterModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const DashboardFilterModal = ({
    isOpen,
    onClose
}: DashboardFilterModalProps) => {
    const {
        isBuildingTypeDropdownOpen,
        buildingTypeOptions,
        filters,
        toggleBuildingTypeDropdown,
        setPriceRange,
        setPropertyType,
        onApplyFilters,
        onClearFilters
    } = useDashboardFilterModal();

    if (!isOpen) {
        return <></>;
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-30">
            <div className="bg-white rounded-lg w-96 p-4 shadow-lg relative">
                <button
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <h2 className="text-lg font-medium mb-4 flex items-center text-primary">
                    <IoOptionsOutline className="mr-2 text-gray-400 text-xl" />
                    Filters
                </h2>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Price Range
                            <Slider
                                className="custom-slider"
                                min={10}
                                max={60}
                                range
                                defaultValue={[
                                    filters.priceRange?.minimum,
                                    filters.priceRange?.maximum
                                ]}
                                onChange={(value) =>
                                    setPriceRange(value[0], value[1])
                                }
                                value={[
                                    filters.priceRange?.minimum,
                                    filters.priceRange?.maximum
                                ]}
                            />
                        </label>
                        <div className="flex items-center justify-around space-x-2">
                            <p className="border-solid border-2 border-gray-200 rounded-md px-10 py-1 text-sm">
                                {filters?.priceRange?.minimum ?? 20}M
                            </p>
                            <p className="text-4xl text-accent">-</p>
                            <p className="border-solid border-2 border-gray-200 rounded-md px-10 py-1 text-sm">
                                {filters?.priceRange?.maximum ?? 45}M
                            </p>
                        </div>
                    </div>
                    <div className="relative">
                        <button
                            id="dropdownBgHoverButton"
                            data-dropdown-toggle="dropdownBgHover"
                            className="w-full justify-between border text-black focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center"
                            type="button"
                            onClick={toggleBuildingTypeDropdown}
                        >
                            Property Type{' '}
                            <svg
                                className="w-2.5 h-2.5 ms-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m1 1 4 4 4-4"
                                />
                            </svg>
                        </button>
                        <div
                            id="dropdownBgHover"
                            className={`absolute z-10 ${isBuildingTypeDropdownOpen ? '' : 'hidden'} w-full h-48 overflow-y-auto shadow-lg bg-white rounded-lg shadow`}
                        >
                            <p className="px-5 py-2 bg-gray-50 text-sm font-semibold">
                                Building type
                            </p>
                            <ul
                                className="pl-3 pb-2 space-y-1 text-sm text-gray-700 prevent-select"
                                aria-labelledby="dropdownBgHoverButton"
                            >
                                {buildingTypeOptions.map(({ value, label }) => (
                                    <li key={value}>
                                        <div className="flex items-center p-2 rounded hover:bg-gray-100">
                                            <input
                                                id={`checkbox-${value}`}
                                                type="checkbox"
                                                value={value}
                                                checked={
                                                    !!filters.type?.includes(
                                                        parseInt(value)
                                                    )
                                                }
                                                onChange={(event) =>
                                                    setPropertyType(
                                                        event.target.value,
                                                        event.target.checked
                                                    )
                                                }
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                                            />
                                            <label
                                                htmlFor={`checkbox-${value}`}
                                                className="w-full ms-2 text-sm font-medium text-gray-900 rounded"
                                            >
                                                {label}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <select className="block w-full mt-1 border border-gray-200 rounded-md shadow-sm p-2">
                            <option>Amenities</option>
                        </select>
                        <select className="block w-full mt-1 border border-gray-200 rounded-md shadow-sm p-2">
                            <option>Revenue</option>
                        </select>
                        <select className="block w-full mt-1 border border-gray-200 rounded-md shadow-sm p-2">
                            <option>Zoning</option>
                        </select>
                        <div className="flex justify-end p-2 border-solid border-b-2 border-b-gray-200">
                            <button className="mt-2 text-accent text-sm">
                                Show more
                            </button>
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 pb-4">
                            Overlays:
                        </label>
                        <div className="grid grid-cols-2 gap-2">
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Carbon Emission</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Flood Risk</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Climate Risk</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Traffic</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Water Detection</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Ecoregions</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Food Desert</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <span className="ml-2">Solar Panels</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-around">
                    <button
                        className="px-4 py-2 bg-primary text-white rounded-md"
                        onClick={() => {
                            onApplyFilters();
                            onClose();
                        }}
                    >
                        Apply Filters
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                        onClick={() => {
                            onClearFilters();
                            onClose();
                        }}
                    >
                        Clear Filter
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DashboardFilterModal;
