import React from 'react';
import { PaneWindowType } from '../state/PaneWindowDefaults';
import { IReducerAction } from '../../../../../../../shared/models/IReducerAction';

interface PaneWindowCalculatorContextType {
    state: PaneWindowType | undefined;
    dispatch: React.Dispatch<IReducerAction>;
}

const PaneWindowCalculatorContext =
    React.createContext<PaneWindowCalculatorContextType>(
        {} as PaneWindowCalculatorContextType
    );

export default PaneWindowCalculatorContext;
