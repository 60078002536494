import { AxiosResponse } from 'axios';
import { ApiEndpoints, apiEndpoints } from '../../../../core/api/endpoints';
import useAxiosClient, {
    ApiMethod
} from '../../../../shared/hooks/useAxiosClient';
import client from '../../../../core/api/client';

const useProfileApi = () => {
    const axiosClient = useAxiosClient();
    const endpointEditProfile = apiEndpoints[ApiEndpoints.EDIT_PROFILE];
    const endpointResetPassword = apiEndpoints[ApiEndpoints.RESET_PASSWORD];

    const editProfile = (
        firstName: string,
        lastName: string
    ): Promise<AxiosResponse<EditProfileResponse>> => {
        const auth = axiosClient<EditProfileResponse>({
            apiConfig: {
                method: ApiMethod.PUT,
                uri: `${endpointEditProfile}`,
                data: { firstName, lastName }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    const resetPassword = (
        currentPassword: string,
        newPassword: string
    ): Promise<AxiosResponse<void>> => {
        const auth = axiosClient<void>({
            apiConfig: {
                method: ApiMethod.PUT,
                uri: `${endpointResetPassword}`,
                data: { currentPassword, newPassword }
            },
            axiosClientConfig: client
        });

        return auth;
    };

    return { editProfile, resetPassword };
};

export type EditProfileResponse = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
};

export default useProfileApi;
